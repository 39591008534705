import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ListBulletIcon, TableCellsIcon } from '@heroicons/react/24/outline'

export default function SettingDropdown() {
    const [openListSetting, setOpenListSetting] = useState(false);

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                    </MenuButton>
                </div>

                <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                    <MenuItem>
                        <button
                        onClick={() => setOpenListSetting(true)}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none w-full text-left"
                        >
                        列表設定
                        </button>
                    </MenuItem>
                    </div>
                </MenuItems>
            </Menu>
            <ListSetting open={openListSetting} setOpen={setOpenListSetting} />
        </>
    )
}




function ListSetting({open, setOpen}) {

    const handleViewTypeChange = (e) => {
        // store to local storage
        localStorage.setItem("issueReporting_viewType", e.target.dataset.viewtype);
    }
    
    const handleConfirm = () => {
        // refresh page
        window.location.reload();
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />
    
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                    <ListBulletIcon aria-hidden="true" className="size-6 text-indigo-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                      列表設定
                    </DialogTitle>
                    <div className="mt-2">
                      <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-900">檢視方式</h4>
                        <p className="text-sm text-gray-500">選擇您偏好的檢視方式來瀏覽問題列表</p>
                      </div>
                      <div className="flex items-center gap-x-4">
                        <div className="relative flex items-start ">
                          <div className="flex h-6 items-center">
                            <input
                              id="list-view"
                              name="view-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              data-viewtype="list"
                              onChange={handleViewTypeChange}
                              defaultChecked={!localStorage.getItem("issueReporting_viewType") || localStorage.getItem("issueReporting_viewType") === "list"}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="list-view" className="font-medium text-gray-900 flex items-center gap-x-2">
                              列表檢視
                              
                            </label>
                          </div>
                        </div>

                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="table-view" 
                              name="view-type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              data-viewtype="table"
                              onChange={handleViewTypeChange}
                              defaultChecked={localStorage.getItem("issueReporting_viewType") === "table"}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor="table-view" className="font-medium text-gray-900 flex items-center gap-x-2">
                              表格檢視
                              
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={handleConfirm} 
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  >
                    確定
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    取消
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
    )
}
