import GlobalVars from "../../../../Config";
import { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import Datepicker from 'react-tailwindcss-datepicker'
import FilterComponent from '../../../../Components/FilterComponent'
import ChartRemark from "../ChartRemark";

export default function ManhourOverGivenPeriod() {
    const [filter, setFilter] = useState({
        startDate: '',
        endDate: '',
        type: 'completion-time'
    });
    const [dateValue, setDateValue] = useState(() => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 6);
        return {
            startDate: sevenDaysAgo.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0]
        };
    });

    const [data, setData] = useState([]);

    // update when dateValue change
    useEffect(() => {
        setFilter({
            ...filter,
            startDate: dateValue.startDate,
            endDate: dateValue.endDate
        });
    }, [dateValue]);

    // query for data
    useEffect(() => {
        let url = GlobalVars.ANALYTIC_DOMAIN + "/api/v1/manhours/total-man-hour-over-given-period";

        // add organizationID
        url += `?organizationID=${localStorage.getItem('organizationId')}`;

        // add startDate
        url += `&startDate=${filter.startDate}`;

        // add endDate
        url += `&endDate=${filter.endDate}`;

        // add type
        url += `&type=${filter.type}`;

        // add userId if it exists
        if (filter.userId) {
            if (filter.userId['name'] != "所有員工") {
                url += `&userId=${filter.userId['_id']}`;
            }
        }

        fetch(url, {
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log(response.json())
                throw new Error('Failed to fetch data');
            }
        })
        .then(rawData => {

            // prepare data for display (current format: {x:YYYY-MM-DD, count:number})
            // sort the data by x (date)
            const sortedData = rawData['data'].sort((a, b) => new Date(a.x) - new Date(b.x));

            setData(sortedData);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, [filter]);

    return (
        <ResponsiveContainer width="100%" height={450}>
            <div
                className="flex flex-row justify-between"
            >
                <h1>總工時分析</h1>
                <ChartRemark content="完成時長: 由工作建立開始至完成, 並包含暫停時長
                
                實際工作時長: 由工作建立開始至完成, 不包含暫停時長" />
            </div>
            <div className="flex flex-row gap-4 mt-4">
                <div
                    className="flex flex-col gap-2"
                >
                    <label
                        htmlFor="category"
                        className="block text-sm font-medium text-gray-700" 
                    >
                        類別
                    </label>
                    <select
                        id="category"
                        className="rounded-md text-sm dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                        onChange={(e) => {
                            setFilter({
                                ...filter,
                                type: e.target.value
                            });
                        }}
                        value={filter.type}
                    >
                        <option value="completion-time">完成時長</option>
                        <option value="work-time">實際工作時長</option>
                    </select>
                </div>
                <div
                    className="flex flex-col gap-2 w-[40%]"
                >
                    <label
                        htmlFor="date-range"
                        className="block text-sm font-medium text-gray-700"
                    >日期</label>
                    <Datepicker 
                        showShortcuts={true}
                        value={dateValue}
                        onChange={(date) => {
                            setDateValue({
                                startDate: date.startDate,
                                endDate: date.endDate,
                            });
                        }}
                        placeholder='選擇接單日期'
                        inputClassName="rounded-md text-sm dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                        configs={{
                            shortcuts: {
                                today: "今天",
                                yesterday: "昨日",
                                past: (period) => `過去 ${period} 天`,
                                currentMonth: "本月",
                                pastMonth: "上月",
                            }
                        }}
                    />
                </div>
                <StaffList 
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            <LineChart width={500} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                    dataKey="x" 
                    angle={300}
                    textAnchor="end"
                    height={60}
                    className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
                />
                <YAxis label={{ value: '小時', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
            </LineChart>

        </ResponsiveContainer>
    );
}

function StaffList({filter, setFilter}) {
    const [staffList, setStaffList] = useState([]);
    useEffect(() => {
        // fetch staff list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + sessionStorage.getItem("organizationId"), {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((res) => res.json())
        .then((rawData) => {
            let data = rawData['users']

            // filter out status not equal to "active"
            // data = data.filter((item) => {
            //     return item["status"] === "active";
            // });

            // convert displayName to name
            data.forEach((item) => {
                item["name"] = item["displayName"];
                delete item["displayName"];
            });

            // add "所有員工" to the list
            data.unshift({
                "name": "所有員工",
                "color": "white"
            });


            // remove duplicates based on user id 
            let uniqueData = [];
            let uniqueIds = [];
            for (let i = 0; i < data.length; i++) {
                if (!uniqueIds.includes(data[i]["_id"])) {
                    uniqueData.push(data[i]);
                    uniqueIds.push(data[i]["_id"]);
                }
            }

            setStaffList(uniqueData);

            // pre-select the first option 
            setFilter({
                ...filter,
                userId: uniqueData[0]
            });
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <div
           className="flex flex-col gap-2" 
        >
            <label
                className="block text-sm font-medium text-gray-700" 
            >員工</label>
            <FilterComponent
                data={staffList}
                filter={filter}
                setFilter={setFilter}
                filterKey="userId"
            />
        </div>
    );
}
