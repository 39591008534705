import { useState, useEffect, Fragment } from 'react'

export default function TableView({
    data,
    statuses,
    statusDisplayNamePair,
    setIsEdit,
    setEditId,
    setOpen,
    setEditItemInfo,
    handleIssueResolve,
    handleJobCreation,
    handleIssueCancel,
    handleIssueReopen
}) {

    const [reformattedData, setReformattedData] = useState([]);
    useEffect(() => {
        // return if data is empty or undefined
        if (!data || data.length === 0) return;

        // reformat the data based on date <createdAt>
        const groupedByDate = data.reduce((acc, job) => {
            // Extract just the date part by splitting on space and taking first part
            const date = job?.createdAt.split(' ')[0];
            if (!acc[date]) {
                acc[date] = {
                    date: date,
                    jobs: []
                };
            }
            acc[date].jobs.push({
                ...job,
                href: `/jobs/${job._id}`
            });
            return acc;
        }, {});

        setReformattedData(Object.values(groupedByDate));
    }, [data]);
    
    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                    <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                        工作地點
                    </th>
                    <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        時間
                    </th>
                    <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        施工同事
                    </th>
                    <th 
                        scope="col" 
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                        事件描述
                    </th>
                    <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-900"
                    >
                        狀態
                    </th>
                    
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {reformattedData.map((dateGroup) => (
                        <Fragment key={dateGroup.date}>
                            <tr className="border-t border-gray-200">
                                <th
                                    scope="colgroup"
                                    colSpan={6}
                                    className="bg-gray-200 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                >
                                    <div className="flex items-center justify-between">
                                        <span>
                                            {dateGroup.date}
                                        </span>
                                        {/* <span
                                            className="flex items-center"
                                        >
                                            {statusCount[dateGroup.date] && Object.keys(statusCount[dateGroup.date]).map((key) => (
                                                <p className="ml-3 text-xs sm:text-sm" key={key}>{key}: {statusCount[dateGroup.date][key]}</p>
                                            ))}
                                        </span> */}
                                    </div>
                                </th>
                            </tr>
                            {dateGroup.jobs.map((job, jobIdx) => (
                                <tr
                                    key={job._id}
                                    className={classNames(
                                        jobIdx === 0 ? 'border-gray-300' : 'border-gray-200',
                                        'border-t hover:bg-gray-200 duration-300 transition-all cursor-pointer'
                                    )}
                                    onClick={() => {
                                        setIsEdit(true);
                                        setEditId(job._id);
                                        setOpen(true);
                                        setEditItemInfo(job);
                                    }}
                                >
                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 font-medium ">
                                        {Array.isArray(job.locationName) ? 
                                            (job.locationName.length > 1 ? "多個工作地點" : job.locationName[0]) 
                                            : job.locationName}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{job.createdAt.split(' ')[1].split(':').slice(0,2).join(':')}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{job.staffID}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500  max-w-xs text-ellipsis overflow-hidden whitespace-nowrap">{job.remarks}</td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-right">
                                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statuses[job.status]}`}>
                                            {statusDisplayNamePair[job.status]}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
                </table>
            </div>
            </div>
        </div>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
