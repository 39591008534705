import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../Config';
import { useNavigate } from 'react-router-dom';
import { RemoveNodeMarkStep } from '@tiptap/pm/transform';

export default function RemarkDialog({
    open,
    setOpen,
    activeBoardID
}) {
    // state
    const [remark, setRemark] = useState('');
    const [fileNames, setFileNames] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    // retrieve the remark and attachments
    useEffect(() => {
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?itemID=${activeBoardID}&organizationID=${sessionStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                // set the remark and attachments to empty
                setRemark('')
                setFileNames([])
                console.log(response.json())
                throw new Error('Error fetching remark and attachments')
            }
        })
        .then(data => {
            console.log('data', data)
            setRemark(data.remarks)
            setFileNames(data.attachments)
        })
        .catch(error => console.error('Error fetching remark and attachments:', error))
    }, [activeBoardID])

    // handle add new project
    const [isLoading, setIsLoading] = useState(false);
    const handleEditRemark = () => {
        setIsLoading(true);

        const body = {
            remarks: remark,
            attachments: fileNames,
            itemID: activeBoardID
        }
        console.log('body', body)

        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
            method: 'PUT',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log(response.json())
                alert('編輯失敗')
                throw new Error('編輯失敗')
            }
        })
        .then(data => {
            setOpen(false)
        })
        .catch(error => console.error('Error adding new project:', error))
        .finally(() => setIsLoading(false));
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
                transition
                className="relative transform rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-lg">
                    <div className="sm:flex sm:items-start mb-4">
                        <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                        <PlusIcon aria-hidden="true" className="size-6 text-indigo-600" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            編輯備註
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                請輸入備註或上載附件。
                            </p>
                        </div>
                        </div>
                    </div>
                    <div>
                        <FormBody 
                            remark={remark}
                            setRemark={setRemark}
                            fileNames={fileNames}
                            setFileNames={setFileNames}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                        />
                    </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-b-lg">
                <button
                    type="button"
                    onClick={handleEditRemark}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                    {isLoading ? '編輯中...' : '編輯'}
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                    取消
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
    )
}

// include all the inputs and labels
function FormBody({
    remark,
    setRemark,
    fileNames,
    setFileNames,
    selectedFiles,
    setSelectedFiles
}) {
    

    const handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files);
        setSelectedFiles(filesArray);
        setFileNames(filesArray.map(file => file.name));
    };

    const handleDeleteFile = (fileToDelete) => {
        setSelectedFiles(prevFiles => 
            prevFiles.filter(file => file.name !== fileToDelete.name)
        );

        setFileNames(prevFiles => 
            prevFiles.filter(file => file.name !== fileToDelete.name)
        );
    };

    return (
        <div className="space-y-4">
            <div>
                <label htmlFor="remarks" className="block text-sm/6 font-medium text-gray-900">
                    備註
                </label>
                <div className="mt-2">
                    <textarea
                        id="remarks"
                        name="remarks"
                        rows={4}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="attachments" className="block text-sm/6 font-medium text-gray-900">
                    附件
                </label>
                <div className="mt-2">
                    <input
                        id="attachments"
                        name="attachments"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
                    />
                </div>
                {selectedFiles.length > 0 && (
                    <div className="mt-4 space-y-2">
                        {selectedFiles.map((file, index) => (
                            <div key={index} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md">
                                <span className="text-sm text-gray-500">{file.name}</span>
                                <button
                                    type="button"
                                    onClick={() => handleDeleteFile(file)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <XMarkIcon className="h-5 w-5" />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}


