import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { ExclamationTriangleIcon, PlusIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../Config';
import { useNavigate } from 'react-router-dom';

export default function AddNewProject({
    open,
    setOpen
}) {
    const [boardName, setBoardName] = useState('');

    const [projectID, setProjectID] = useState('');
    useEffect(() => {
        const id = window.location.pathname.split('/')[2];
        setProjectID(id);
    }, [])

    const navigate = useNavigate();

    // handle add new project
    const [isLoading, setIsLoading] = useState(false);
    const handleAddNewBoard = () => {

        let body = {
            boardTitle: boardName,
            organizationID: localStorage.getItem('organizationId'),
            projectID: projectID
        }

        // make sure all fields are filled
        if (!body.boardTitle) {
            alert('請填寫所有欄位')
            return
        }

        // prompt user to confirm
        if (!window.confirm('確定要新增項目嗎？')) {
            return
        }


        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board`, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                alert('新增失敗')
            }
        })
        .then(data => {
            alert('新增成功, 現在為你刷新頁面')
            window.location.reload();
        })
        .catch(error => console.error('Error adding new project:', error));
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
                transition
                className="relative transform rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-lg">
                    <div className="sm:flex sm:items-start mb-4">
                        <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                        <PlusIcon aria-hidden="true" className="size-6 text-indigo-600" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            新增項目版
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                            Please enter the project name, date, and responsible person.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div>
                        <FormBody 
                            boardName={boardName}
                            setBoardName={setBoardName}
                        />
                    </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-b-lg">
                <button
                    type="button"
                    onClick={handleAddNewBoard}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                    {isLoading ? '新增中...' : '新增'}
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                    取消
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
    )
}

// include all the inputs and labels
function FormBody({
    boardName,
    setBoardName,
}) {
    return (
        <div
            className="space-y-4"
        >
            <div>
            <label htmlFor="boardName" className="block text-sm/6 font-medium text-gray-900">
                Board Name
            </label>
            <div className="mt-2">
                <input
                id="boardName"
                name="boardName"
                type="text"
                value={boardName}
                onChange={(e) => setBoardName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                />
                </div>
            </div>
            
        </div>
    )
}
