import { useState, useEffect } from 'react';
import GlobalVars from '../../../../Config';

export default function MainTypeAssistant({
    setMainType,
    mainType,
    locationIDs,
    mainTypeList
}) {
    const [suggestions, setSuggestions] = useState([])

    // fetch suggestions from backend
    useEffect(() => {
        // return if locationIDs is empty
        if (locationIDs.length === 0) {
            return
        }

        fetch(`${GlobalVars.ASSISTANT_DOMAIN}/api/v1/job/main-job-type-assistant`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "locationIDs": locationIDs
            })
        })
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error("Failed to fetch suggestion")
            }
        })
        .then(data => {
            let matchedTypes = []
            data['response'].forEach(typeId => {
                const matchedType = mainTypeList.find(mainType => mainType._id === typeId)
                
                if (matchedType) {
                    matchedTypes.push(matchedType)
                }
            })
            setSuggestions(matchedTypes);
        })
        .catch(err => console.log(err))
    }, [locationIDs, mainTypeList])


    const handleSuggestionClick = (suggestion) => {
        // set main type
        setMainType(suggestion)
    }

    return (
        <>
            <span className="text-xs text-gray-500">建議選項</span>
            {suggestions.length > 0 && (
                <div
                    className="flex flex-wrap gap-2"
                >
                    {suggestions.map(suggestion => (
                        <button
                            key={suggestion._id}
                            className="ai-suggestion text-left inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white"
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.langVar.tc}
                        </button>
                    ))}
                </div>
            )}
        </>
    )
}