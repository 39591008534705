import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon, DocumentMagnifyingGlassIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function StackedList({
    jobs,
    statusCount,
    selectedJobId,
    displaySecondaryScreen,
    setSelectedJobId,
    dateSortText,
    invPriorityMapping,
    statuses,
    handleProgressJob,
}) {
    return (
        <>
        {jobs.map((job) => (
            <div key={job._id}>
              {/* if it's the first job of the day, show date */}
              {job.firstJobOfDay && (
                <div 
                  className='bg-gray-200 px-1 py-1 text-sm font-semibold text-gray-700 first-job-of-day flex items-center justify-between'
                >
                  <p
                    className='skip-trans'
                  >{job?.date?.substring(0, 10)}</p>
                  <div className='flex items-center'>
                    {statusCount[job.date.substring(0, 10)] && Object.keys(statusCount[job.date.substring(0, 10)]).map((key) => (
                        <p className="ml-3 text-xs sm:text-sm" key={key}>{key}: {statusCount[job.date.substring(0, 10)][key]}</p>
                    ))}
                  </div>
                </div>  
              )}
              <li key={job._id} className={`${job._id === selectedJobId ? "bg-indigo-100" : ""} flex items-center justify-between gap-x-6 py-4 px-1`} data-jobid={job._id}>
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    {!displaySecondaryScreen ? (
                      <a 
                        className="text-sm font-semibold leading-6 text-gray-900 skip-trans hover:underline duration-250" 
                      href={job.href}>
                      {Array.isArray(job.locationName) ? (job.locationName.length > 1 ? "多個工作地點" : job.locationName[0]) : job.locationName} | {Array.isArray(job.typeName) ? (job.typeName.length > 1 ? "多個工作" : job.typeName[0]) : job.typeName}
                    </a>
                    ) : (
                      <button 
                        className="text-sm font-semibold leading-6 text-gray-900 skip-trans text-left hover:underline duration-250" 
                        onClick={() => {
                          setSelectedJobId(job._id);
                        }}
                      >
                        {Array.isArray(job.locationName) ? (job.locationName.length > 1 ? "多個工作地點" : job.locationName[0]) : job.locationName} | {Array.isArray(job.typeName) ? (job.typeName.length > 1 ? "多個工作" : job.typeName[0]) : job.typeName}
                      </button>
                    )}
                  </div>
                  <div 
                    className="mt-1 sm:flex sm:items-center sm:gap-x-2 text-xs leading-5 text-gray-500 cursor-pointer"
                    onClick={() => {
                      window.location.href = job.href;
                    }}
                  >
                    <p className="overflow-auto whitespace-nowrap">{dateSortText}: {job.date}</p>
                    <p className="overflow-auto whitespace-nowrap">施工同事: <span className="skip-trans">{job.assignedTo}</span></p>
                  </div>
                  {job.jobPriority && job.jobPriority != 0 ? (
                    <div
                      className="mt-1 sm:flex sm:items-center sm:gap-x-2 text-xs leading-5 text-gray-500 cursor-pointer"
                    >
                      <div
                        className={classNames(
                          job.jobPriority === 1 ? "bg-green-300" :
                          job.jobPriority === 2 ? "bg-yellow-300" :
                          job.jobPriority === 3 ? "bg-red-300 text-red-900" : "",
                          "rounded w-5 h-5 flex items-center justify-center"
                        )}
                      >
                        <p>{invPriorityMapping[job.jobPriority]}</p>
                      </div>
                    </div>
                  ):(<></>)}
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <p
                    className={classNames(
                      statuses[job.alertOnly && job.status['status'] != "取消" ? "通知" : job.status['status']],
                      'rounded-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-sm font-semibold ring-1 ring-inset cursor-pointer'
                    )}
                    onClick={() => {
                      window.location.href = job.href;
                    }}
                  >
                    {job.alertOnly && job.status['status'] != "取消"  ? "通知" : job.status['status']}
                  </p>
                  <Menu as="div" className="relative flex-none">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={job.href}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'px-3 py-1 text-sm leading-6 text-gray-900 flex items-center cursor-pointer'
                              )}
                            >
                              <DocumentMagnifyingGlassIcon className="mr-2 h-5 w-5 inline-block" />
                              查看詳細<span className="sr-only">, {job.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                        {!job.alertOnly && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => handleProgressJob(job)}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                              )}
                            >
                              <ArrowTrendingUpIcon className="mr-2 h-5 w-5 inline-block" />
                              {job.progressJobBtnText}
                            </a>
                          )}
                        </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </li>
            </div>
          ))}
        </>
    )
}