
import '../../styles.scss'
import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react'
import { useParams } from 'react-router-dom';

import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import debounce from 'lodash/debounce'

import GlobalVars from '../../../../../../Config';

import { useState, useEffect, useCallback, useRef } from 'react'

// import heroicon
import {
    ListBulletIcon,
    ArrowPathIcon,
    TableCellsIcon
} from '@heroicons/react/24/outline'
import NumberedListIcon from '../NumberedListIcon'

// define your extension array
const extensions = [
    StarterKit,
    Document,
    Paragraph,
    Text,
    BulletList,
    ListItem,
    OrderedList,
    Heading.configure({
        levels: [1, 2, 3]
    }),
    Table,
    TableCell,
    TableHeader,
    TableRow
]


const Tiptap = ({initialContent}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // save the content to the database
    const [savingText, setSavingText] = useState('')

    // Wrap handleContentChange in useCallback
    const handleContentChange = useCallback(async (content) => {
        const projectID = window.location.href.split('/').pop();

        const body = {
            "projectID": projectID,
            "note": content
        };

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/project`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                setSavingText('Failed to save');
                throw new Error('Error saving note');
            } else {
                setSavingText(`Last saved ${new Date().toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(',', '')}`);
            }
        } catch (error) {
            console.error('Save failed:', error);
            throw error;
        }

        

    }, []); // Empty dependency array since none of these values change


    // Debounced save function
    const debouncedSave = useCallback(
        debounce(async (content) => {
            try {
                setIsSaving(true);
                setSavingText('Saving...');
                await handleContentChange(content);
                
            } catch (error) {
                console.error('Failed to save:', error);
                // Optionally show error toast
            } finally {
                setIsSaving(false);
            }
        }, 1000), // Wait 1 second after last change before saving
        [handleContentChange]
    );

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedSave.cancel();
        };
    }, [debouncedSave]);

    const editor = useEditor({
        extensions,
        content: initialContent,
        onUpdate: ({editor}) => {
            setIsEditing(true);
            const content = editor.getHTML()
            debouncedSave(content)
        }
    });

   
    let {id} = useParams();
    // Set initial content on first mount
    useEffect(() => {
        if (editor && initialContent && !isEditing) {
            const projectID = id;
            if (projectID.length != 24) {
                return;
            }

            let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${projectID}&organizationID=${sessionStorage.getItem('organizationId')}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Error fetching project')
                }
            })
            .then(data => {
                if (data.note) {
                    editor.commands.setContent(data.note);
                }
            })
            .catch(error => {
                console.error('Error fetching project:', error);
            })
        }
    }, [editor]); // Only run when editor is initialized

    // handle control group floading
    const controlGroupRef = useRef(null); // Reference for the control group
    const placeholderRef = useRef(null); // Reference for the placeholder to prevent layout shift
    const [isFloating, setIsFloating] = useState(false); // State to track floating status
    const [placeholderHeight, setPlaceholderHeight] = useState(0); // To store the height of the control group

    useEffect(() => {
        const handleScroll = () => {
            if (controlGroupRef.current && placeholderRef.current) {
                const placeholderTop = placeholderRef.current.getBoundingClientRect().top;

                // Check if the placeholder has scrolled out of view
                if (placeholderTop <= 0) {
                setIsFloating(true);
                } else {
                setIsFloating(false);
                }
            }
        };

        const calculatePlaceholderHeight = () => {
            if (controlGroupRef.current) {
                // Set the placeholder height to match the control group's height
                setPlaceholderHeight(controlGroupRef.current.offsetHeight);
            }
        };

        // Attach scroll and resize listeners
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", calculatePlaceholderHeight);

        // Calculate the height initially
        calculatePlaceholderHeight();

        // Cleanup listeners on unmount
        return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", calculatePlaceholderHeight);
        };
    }, []);

    return (
        <div
        >
            {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={`font-bold ${editor.isActive('bold') ? 'is-active' : ''}`}
                >
                    Bold
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={`italic ${editor.isActive('italic') ? 'is-active' : ''}`}
                >
                    Italic
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={`line-through ${editor.isActive('strike') ? 'is-active' : ''}`}
                >
                    Strike
                </button>
            </BubbleMenu>}

            {editor && <FloatingMenu className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <ListBulletIcon className="w-4 h-4" />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <NumberedListIcon />
                </button>
                <button
                    onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                    }
                >
                    <TableCellsIcon className="w-4 h-4" />
                </button>
            </FloatingMenu>}
            {/* Placeholder to prevent layout shifts */}
            <div ref={placeholderRef} style={{ height: isFloating ? placeholderHeight : 0 }}></div>
            {/* <div
                className={`transition-all duration-300 flex items-center justify-between mb-5 ${
                    isFloating
                      ? "fixed top-0 left-0 w-full bg-white shadow-md z-50"
                      : "relative"
                  }`}
                style={{ backdropFilter: 'blur(8px)', backgroundColor: 'rgba(255, 255, 255, 0.95)' }}
                ref={controlGroupRef}
            >
                <ControlGroup editor={editor} />
                <p className="text-sm text-gray-500 flex items-center gap-1">
                    {savingText}
                    {savingText === 'Saving' && <ArrowPathIcon className="w-4 h-4 animate-spin" />}
                </p>
            </div> */}
            <div
                className="flex items-center justify-between mb-5"
            >
                <ControlGroup editor={editor} />
                <LastSavedText savingText={savingText} />
            </div>
            <EditorContent editor={editor} />
        </div>
    )
}

export default Tiptap;

function ControlGroup({editor}) {
    return (
        <div className="control-group">
            <div className="button-group flex items-center gap-2">
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <ListBulletIcon className="w-4 h-4" />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <NumberedListIcon />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                    H3
                </button>
                {/* <button
                    onClick={() => editor.chain().focus().splitListItem('listItem').run()}
                    disabled={!editor.can().splitListItem('listItem')}
                >
                    Split list item
                </button>
                <button
                    onClick={() => editor.chain().focus().sinkListItem('listItem').run()}
                    disabled={!editor.can().sinkListItem('listItem')}
                >
                    Sink list item
                </button>
                <button
                    onClick={() => editor.chain().focus().liftListItem('listItem').run()}
                    disabled={!editor.can().liftListItem('listItem')}
                >
                    Lift list item
                </button> */}
            </div>
      </div>
    )
}

function LastSavedText({savingText}) {
    return (
        <p className="text-sm text-gray-500 flex items-center gap-1">
            {savingText}
            {savingText === 'Saving' && <ArrowPathIcon className="w-4 h-4 animate-spin" />}
        </p>
    )
}