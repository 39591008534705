import { useState, useEffect } from 'react'
import { Menu, MenuButton, MenuItems } from '@headlessui/react'
import { PlusIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../Config';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';

export default function MembersSelect({
    outputs,
    setOutputs
}) {
    const [memberList, setMemberList] = useState([]);
    useEffect(() => {
        // fetch member list
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch members')
            }
        })
        .then(data => {
            setMemberList(data['users'].map(user => ({
                displayName: user.displayName,
                _id: user._id
            })))
        })
        .catch(error => console.error('Error fetching members:', error));
    }, [])

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="relative inline-flex size-8 shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="sr-only">Open options</span>
            <PlusIcon aria-hidden="true" className="size-5" />
          </MenuButton>
        </div>
  
        <MenuItems
          transition
          className="absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MembersCombobox 
                memberList={memberList}
                outputs={outputs}
                setOutputs={setOutputs}
            />
          </div>
        </MenuItems>
      </Menu>
    )
}

function MembersCombobox({
    memberList, 
    outputs,
    setOutputs,

}) {
    const [query, setQuery] = useState('');

    const filteredPeople = query.length < 1 
        ? [] 
        : memberList.filter((person) => 
            person.displayName.toLowerCase().includes(query.toLowerCase())
        );

    return (
        <Combobox
            as="div"
            value={outputs.assignedTo}
            onChange={(person) => {
                if (!person) return

                setQuery('')
                setOutputs({
                    ...outputs, 
                    assignedTo: outputs.assignedTo ? [...outputs.assignedTo, person] : [person]
                })
            }}
            >
            <div className="relative mt-2">
                <ComboboxInput
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm/6 outline-none"
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => {
                        // Delay clearing the query to allow for selection
                        setTimeout(() => setQuery(''), 200);
                    }}
                    displayValue={(person) => person?.displayName}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                {filteredPeople.length > 0 && (
                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {filteredPeople.map((person) => (
                    <ComboboxOption
                        key={person._id}
                        value={person}
                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                    >
                        <span className="block truncate group-data-[selected]:font-semibold">{person.displayName}</span>

                        <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                        <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                    </ComboboxOption>
                    ))}
                </ComboboxOptions>
                )}
            </div>
        </Combobox>
    )
}