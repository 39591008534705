import { useState, useEffect } from 'react'
import { UserCircleIcon, PencilIcon, TrashIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import GlobalVars from '../../../../Config';

import MembersSelect from '../MemberSelect'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const priorityOpt = [
    { name: '高', value: 'high', color: 'bg-red-500' },
    { name: '中', value: 'medium', color: 'bg-yellow-500' },
    { name: '低', value: 'low', color: 'bg-green-500' },
]

const statusOpt = [
    { name: '待處理', value: 'pending', color: 'bg-red-500' },
    { name: '進行中', value: 'inProgress', color: 'bg-yellow-500' },
    { name: '已完成', value: 'completed', color: 'bg-green-500' },
    { name: '停滯', value: 'stuck', color: 'bg-gray-500' },
]

export default function BoardsComp({ 
    boardName, 
    boardId, 
    newBoardItemsOpen, 
    setNewBoardItemsOpen,
    activeBoardID,
    setActiveBoardID,
    setRemarkDialogOpen
}) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log('items', items)
    }, [items])

    // fetch items
    useEffect(() => {
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?boardID=${boardId}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch items')
            }
        })
        .then(data => {
            setItems(data);
        })
        .catch(error => console.error('Error fetching items:', error));
    }, [boardId, newBoardItemsOpen])

    // handle delete item
    const handleDeleteItem = (itemId) => {
        // prompt for confirmation
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }

        // send request
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                itemID: itemId
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to delete item')
            }
        })
        .then(data => {
            setItems(items.filter(item => item._id !== itemId));
        })
        .catch(error => console.error('Error deleting item:', error));
    }

    // handle update item
    const handleUpdateItem = (itemId, itemKey, itemValue) => {
        console.log('update item', itemId, itemKey, itemValue)
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'PUT'
        let body = {
            itemID: itemId,
            [itemKey]: itemValue,
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update item')
            }
        })
        .then(data => {
            console.log('update item', data)
        })
        .catch(error => console.error('Error updating item:', error));
    }

    // handle remove assignedTo
    const handleRemoveAssignedTo = (itemId, assignedTo) => {
        console.log('remove assignedTo', itemId, assignedTo)

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'DELETE'
        let body = {
            itemID: itemId,
            assignedTo: assignedTo['_id']
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to remove assignedTo')
            }
        })
        .then(data => {
            console.log('remove assignedTo', data)
            setItems(items.map(item => 
                item._id === itemId 
                  ? { ...item, assignedTo: item.assignedTo.filter(user => user._id !== assignedTo._id) } 
                  : item
            ));
        })
        .catch(error => {
          console.error('Error removing assignedTo:', error)
          alert("移除失敗, 請稍後再試")
        });
    }

    return (
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">{boardName}</h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the boards in your project.
            </p> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setNewBoardItemsOpen(true)
                setActiveBoardID(boardId)
              }}
            >
              新增
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="">
            <div className="inline-block min-w-full py-2 align-middle">
                {items.length > 0 ? (
                    <table className="min-w-full border-separate border-spacing-0">
                        <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      工作標題
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell text-center"
                    >
                      優先
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell text-center"
                    >
                      狀態
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell text-center"
                    >
                      負責人
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter text-center"
                    >
                      目標完成日期
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter text-center"
                    >
                      備註
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, itemIdx) => (
                    <tr key={item.title}>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'cursor-text whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                        )}
                      >
                        <input 
                          type="text" 
                          className="w-full border-1 border-white hover:border-gray-300 rounded-md duration-300 transition-all"
                          value={item.itemTitle} 
                          onChange={(e) => {
                            setItems(prevItems => {
                              const newItems = [...prevItems];
                              newItems[itemIdx].itemTitle = e.target.value;
                              return newItems;
                            });
                            handleUpdateItem(item._id, 'itemTitle', e.target.value)
                          }}
                        />
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'hidden whitespace-nowrap px-3 py-1 text-sm text-white sm:table-cell text-center ' + 
                          (item.priority === 'high' ? 'bg-red-500' :
                           item.priority === 'medium' ? 'bg-yellow-500' :
                           item.priority === 'low' ? 'bg-green-500' : 'bg-gray-500'),
                        )}
                      >
                        <PrioritySelect itemIdx={itemIdx} itemID={item._id} priority={item.priority} setItems={setItems} />
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'hidden whitespace-nowrap px-3 py-1 text-sm text-white sm:table-cell text-center ' + 
                          (item.status === 'pending' ? 'bg-red-500' :
                           item.status === 'inProgress' ? 'bg-yellow-500' :
                           item.status === 'completed' ? 'bg-green-500' : 'bg-gray-500'),
                        )}
                      >
                        <StatusSelect itemIdx={itemIdx} itemID={item._id} status={item.status} setItems={setItems} />
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'hidden whitespace-nowrap px-3 py-1 text-sm text-gray-500 lg:table-cell text-center w-[200px]',
                        )}
                      >
                        <div
                          className="flex items-center space-x-1 flex-wrap justify-center"
                        >
                        {item.assignedTo.map((user, index) => (
                           <button
                           key={index}
                           className="group relative inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 hover:bg-gray-300 text-gray-600 text-sm font-medium -ml-1 first:ml-0"
                           title={user.displayName}
                           onClick={() => handleRemoveAssignedTo(item._id, user)}
                         >
                           {/* Initial content - display the first letter of the user's name */}
                           <span className="group-hover:opacity-0 transition-opacity duration-200">
                             {user?.displayName?.charAt(0).toUpperCase()}
                           </span>
                         
                           {/* XMark icon - appears on hover */}  
                           <XMarkIcon
                             className="absolute w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor"
                             strokeWidth={2}
                           />
                         </button>
                        ))}
                        <MembersSelect 
                          outputs={item.assignedTo}
                          setOutputs={(newAssignedTo) => {
                            // check against existing assignedTo
                            if (item.assignedTo.some(user => user._id === newAssignedTo._id)) {
                              alert('已指派此人員')
                              return
                            }
                            
                            // Assuming newAssignedTo can be a single user or an object with an 'assignedTo' array
                            const newAssignedArray = Array.isArray(newAssignedTo) 
                              ? newAssignedTo 
                              : newAssignedTo['assignedTo'] || [newAssignedTo]; // Fallback to single user if not an array

                            setItems(prevItems => 
                              prevItems.map(prevItem => 
                                prevItem._id === item._id 
                                  ? { 
                                      ...prevItem, 
                                      assignedTo: [
                                        ...prevItem.assignedTo, 
                                        ...newAssignedArray // Spread the new assigned users
                                      ] 
                                    } // Append to existing list
                                  : prevItem
                              )
                            );

                            handleUpdateItem(item._id, 'assignedTo', newAssignedArray)
                          }}
                        />
                        </div>
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-1 text-sm text-gray-500 text-center',
                        )}
                      >
                        {item.deadline}
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap px-3 py-1 text-sm text-gray-500 text-center lg:table-cell',
                        )}
                      >
                        <div
                          className="flex justify-center items-center"
                        >
                          <PencilIcon className="mx-3 my-4 w-4 h-4 text-gray-500 cursor-pointer" onClick={() => {
                            setRemarkDialogOpen(true)
                            setActiveBoardID(item._id)
                          }} />
                        </div>
                      </td>
                      <td
                        className={classNames(
                          itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                          'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                        )}
                      >
                        <button className="text-indigo-600 hover:text-indigo-900 cursor-pointer hover:bg-gray-100 rounded-md p-1 duration-300" onClick={() => handleDeleteItem(item._id)}>
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                    </table>
                ) : (
                    <div className="text-center text-gray-500">暫無子項目</div>
                )}
            </div>
          </div>
        </div>
      </div>
    )
}


function PrioritySelect({ itemIdx, itemID, priority, setItems }) {
    const handlePriorityChange = (priorityName) => {
        setItems(prevItems => {
            const newItems = [...prevItems];
            newItems[itemIdx].priority = priorityName;
            return newItems;
        });

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'PUT'
        let body = {
            itemID: itemID,
            priority: priorityName
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update item')
            }
        })
        .then(data => {
            console.log('update item', data)
        })
        .catch(error => console.error('Error updating item:', error));
    }

    useEffect(() => {
        console.log('priority', priority)
    }, [priority])

    return (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 text-sm font-semibold text-white">
              {priorityOpt.find(opt => opt.value === priority)?.name}
              <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-white" />
            </MenuButton>
          </div>
          <MenuItems
            transition
            className="absolute left-0 z-[100] mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
          <div className="py-1">
              {priorityOpt.map((priorityItem) => (
                <MenuItem key={priorityItem.value}>
                    <button
                    onClick={() => {
                      if (priority !== priorityItem.value) {
                        handlePriorityChange(priorityItem.value)
                      }
                    }}
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                    {priorityItem.name}
                    </button>
                </MenuItem>
              ))}
          </div>
          </MenuItems>
        </Menu>
      )
}

function StatusSelect({ itemIdx, itemID, status, setItems }) {
    const handleStatusChange = (statusName) => {
        setItems(prevItems => {
            const newItems = [...prevItems];
            newItems[itemIdx].status = statusName;
            return newItems;
        });

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'PUT'
        let body = {
            itemID: itemID,
            status: statusName
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update item')
            }
        })
        .then(data => {
            console.log('update item', data)
        })
        .catch(error => console.error('Error updating item:', error));
    }

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 text-sm font-semibold text-white">
            {statusOpt.find(opt => opt.value === status)?.name}
            <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-white" />
          </MenuButton>
        </div>
        <MenuItems
        transition
        className="absolute left-0 z-[100] mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {statusOpt.map((statusItem) => (
            <MenuItem key={statusItem.value}>
                <button
                onClick={() => {
                  if (status !== statusItem.value) {
                    handleStatusChange(statusItem.value)
                  }
                }}
                className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                >
                {statusItem.name}
                </button>
            </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    )
  }