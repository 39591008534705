import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StackedListView({
    issueList,
    statuses,
    statusDisplayNamePair,
    filterObj,
    isMobile,
    setIsEdit,
    setEditId,
    setOpen,
    setEditItemInfo,
    handleIssueResolve,
    handleJobCreation,
    handleIssueCancel,
    handleIssueReopen,
}) {
    return (
        <ul role="list" className="divide-y divide-gray-100">
          {issueList.map((item) => (
            <li key={item.id} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900 skip-trans">{item.locationName}</p>
                  <p
                    className={classNames(
                      statuses[item.status],
                      'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                  >
                    {statusDisplayNamePair[item.status]}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  {/* <p className="whitespace-nowrap">
                    Due on <time dateTime={item.dueDateTime}>{item.dueDate}</time>
                  </p>
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg> */}
                  <p className="sm:truncate whitespace-nowrap">創建日期 {item.createdAt}</p>
                  <p className="sm:truncate text-white hidden"> {item._id}</p>
                </div>
                <div
                  className="flex mt-1 sm:flex-row flex-col"
                >
                  {item.humanReadableId && (
                    <div
                      className="mr-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        工單編號: {item.humanReadableId}
                      </span>
                    </div>
                  )}
                  {item.name ? (
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="skip-trans inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        創建: {item.name}
                      </span>
                    </div>
                  ):(
                    item.assignerEmail && (
                      <div
                        className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                      >
                        <span
                          className="skip-trans inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                        >
                          創建: {item.assignerEmail}
                        </span>
                      </div>
                    )
                  )}
                  {item.staffID && (
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block max-w-[250px] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                      >
                        負責: {item.staffID}
                      </span>
                    </div>
                  )}
                  
                </div>
                {filterObj?.description && (
                  <div
                    className="mt-1 sm:flex-row flex-col"
                  >
                    <div
                      className="mr-1 sm:mt-0 mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                    >
                      <span
                        className="inline-block max-w-[100%] truncate whitespace-nowrap rounded-md bg-gray-100 px-2.5 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                        id="issue-list-description"
                      >
                        描述: {item.remarks}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <a
                    data-id={item._id}
                    onClick={() => {
                        setIsEdit(true);
                        setEditId(item._id);
                        setOpen(true);
                        setEditItemInfo(item);
                    }}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block cursor-pointer"
                >
                  查看<span className="sr-only">, {item.name}</span>
                </a>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {isMobile && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                                data-id={item._id}
                                onClick={() => {
                                    setIsEdit(true);
                                    setEditId(item._id);
                                    setOpen(true);
                                    setEditItemInfo(item);
                                }}
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                )}
                            >
                              查看<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {item.status != "Resolved" && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={(e) => handleIssueResolve(e, item)}
                            data-id={item._id}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}
                          >
                            {item.status == "Pending" && item.status != "Resolved" ? "接受工單" : "完成工單"}<span className="sr-only">, {item.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleIssueCancel}
                            data-id={item._id}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                            )}
                          >
                            取消<span className="sr-only">, {item.name}</span>
                          </a>
                        )}
                      </Menu.Item>
                      {item.status !== "Pending" && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={handleIssueReopen}
                              data-id={item._id}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900, cursor-pointer'
                              )}
                            >
                              重開<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={(e) => handleJobCreation(e, item)}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900, cursor-pointer'
                              )}
                            >
                              生成工作<span className="sr-only">, {item.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          ))}
        </ul>
    )
}