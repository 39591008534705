import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'


// KPI involves
// - number of jobs today
// - number of jobs this week
// - number of jobs this month
// Each statistics compared with the last period
const KPIList = [
    {type: "today", typeName: "今天"},
    {type: "this-week", typeName: "今星期"},
    {type: "this-month", typeName: "今月"},
]


export default function KPIDisplay({filter}) {
       

    return (
        <>
            <div
                className="flex flex-row items-center mb-4"
            >
                <h3 className="text-base font-semibold text-gray-900 whitespace-nowrap mr-1">工作數量</h3>  
                <KPIInfoTooltip />
            </div>
            <div
                className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 border border-gray-200 rounded-lg"
            >
                
                {KPIList.map((kpi) => (
                    <div
                        key={kpi.type}
                    >
                        <KPIDisplayComp 
                            type={kpi.type} 
                            typeName={kpi.typeName}
                            filter={filter}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

function KPIDisplayComp({type, typeName, filter}) {
    const [KPIs, setKPI] = useState(null);

    const fetchKPI = (kpi) => {
        let url = `${GlobalVars.ANALYTIC_DOMAIN}/api/v1/kpis/calculation?type=${kpi}&organizationID=${sessionStorage.getItem("organizationId")}`
        if (filter?.job_type) {
            url += `&job_type=${filter.job_type.id}`
        }
        console.log(url)
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                
                throw new Error("Failed to fetch KPI");
            })
            .then(data => {
                console.log(data);
                setKPI(data['data'])
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        fetchKPI(type);
    }, [filter]);

    return (
        <div
          key={type}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-5 sm:px-6 xl:px-8"
        >
          <div className="text-sm/6 font-medium text-gray-500">{typeName}</div>
          <div
            className={classNames(
              KPIs && KPIs?.percent_change < 0 ? 'text-rose-600' : 'text-green-600',
              'text-xs font-medium',
            )}
          >
            {KPIs ? `${KPIs.percent_change}%` : "加載中"}
          </div>
          <div className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">{KPIs && KPIs?.current_period.number_of_jobs || "加載中"}</div>
        </div>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function KPIInfoTooltip() {
    return (
        <Menu as="div" className="relative inline-block text-left dynamic-comp">
          <div>
            <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <span className="sr-only">Open options</span>
              <QuestionMarkCircleIcon aria-hidden="true" className="size-5" />
            </MenuButton>
          </div>
    
          <MenuItems
            transition
            className="absolute left-0 z-10 mt-2 whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="p-3 text-sm">
              百分比顯示的是同期比較. 顯示會受工作類型篩選影響.
            </div>
          </MenuItems>
        </Menu>
      )
}
