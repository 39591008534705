import { Fragment, useState, useRef } from 'react'
import { 
  Menu, 
  Transition, 
  Listbox, 
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import { CalendarIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { CheckIcon, ChevronUpDownIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import {
  ArrowTrendingUpIcon,
  DocumentMagnifyingGlassIcon,
  FunnelIcon,
  ChevronDownIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import GlobalVars from '../../Config'
import SearchableMenu from '../SearchableMenu'
import Datepicker from 'react-tailwindcss-datepicker'
import "./JobList.css"
import Select, { components, DropdownIndicatorProps } from 'react-select'
import { FixedSizeList as WindowList } from 'react-window';

// components
import TableView from './components/TableView'
import StackedList from './components/StackedList'

const statuses = {
  "新工作": 'text-white-600 bg-white-50 ring-white-500/10',
  "待分配": "text-white-600 bg-white-50 ring-white-500/10",
  "待確認": "text-pink-600 bg-pink-50 ring-pink-500/10",
  "未開始": "text-gray-600 bg-gray-50 ring-gray-500/10",
  "進行中": "text-green-600 bg-green-50 ring-green-500/10",
  "中斷": "text-red-600 bg-red-50 ring-red-500/10",
  "待續": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "待檢查": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "待批准": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "完成": "text-orange-600 bg-orange-50 ring-orange-500/10",
  "拒絕": "text-red-600 bg-red-50 ring-red-500/10",
  "取消": "text-red-600 bg-red-50 ring-red-500/10",
  "延期": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "回公司": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "緊急": "text-red-600 bg-red-50 ring-red-500/10",
  "維修中": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "需要額外部件": "",
  "需要專家幫助": "",
  "通知": "text-lime-600 bg-lime-50 ring-lime-500/10",
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function JobList({
  jobs, 
  statusList, 
  filterStatus, 
  setFilterStatus, 
  locList, 
  staffList, 
  selectedLoc, 
  setSelectedLoc, 
  selectedStaff, 
  setSelectedStaff, 
  dateFilter, 
  setDateFilter, 
  estimateDate, 
  setEstimateDate,
  typeList,
  selectedTaskType,
  setSelectedTaskType,
  taskList,
  selectedTask,
  setSelectedTask,
  handleProgressJob,
  isBatchChange,
  setBatchSelectedJobs,
  batchSelectedJobs,
  batchSelectedStatus,
  setBatchSelectedStatus,
  manualRefreshBtnText,
  selectedJobId,
  setSelectedJobId,
  numJobDisplay,
  setNumJobDisplay,
  setIsLoadingNewData,
  displaySecondaryScreen,
}) {
  // // set states
  // const [exportDialog, setExportDialog] = useState(false);
  
  var statusCountPerDay = {}

  // check if jobs is a list and not empty
  if (Array.isArray(jobs) && jobs.length !== 0) {

    for (let i = 0; i < jobs.length; i++) {
      jobs[i].href = "/jobs/" + jobs[i]._id;  // add job href to job list (/jobs/:id)

      // check if it's the first job of the day (if yes, add firstJobOfDay: true)
      if (i === 0) {
        jobs[i].firstJobOfDay = true;
      } else {
        if (jobs[i].date.substring(0, 10) !== jobs[i-1].date.substring(0, 10)) {
          jobs[i].firstJobOfDay = true;
        } else {
          jobs[i].firstJobOfDay = false;
        }
      }
      // create button text based on status
      if (jobs[i].status['status'] == '新工作' || jobs[i].status['status'] == '待確認' || jobs[i].status['status'] == '已轉移') {
        if (jobs[i].assignedTo == sessionStorage.getItem('userid')) {
          // primary action change to 接單  
          jobs[i].progressJobBtnText = '接單';
        } else {
          jobs[i].progressJobBtnText = '收到工作';
        }

      } else if (jobs[i].status['status'] == '未開始') {
        // primary action change to 開始工作
        jobs[i].progressJobBtnText = '開始工作';

      } else if (jobs[i].status['status'] == '進行中') {
        if (jobs[i].meta.hasOwnProperty('requiredInspection') && jobs[i].meta.requiredInspection == true) {
          // primary action change to 待檢查
          jobs[i].progressJobBtnText = '待檢查';
        } else {
          // primary action change to 完成工作
          jobs[i].progressJobBtnText = '完成工作';
        }
      } else if (jobs[i].status['status'] == '待檢查') {
        // primary action change to 完成工作
        jobs[i].progressJobBtnText = '完成工作';
      } else if (jobs[i].status['status'] == '完成') {
        // primary action change to 重新開始
        jobs[i].progressJobBtnText = '重新開始';
      } else {
        jobs[i].progressJobBtnText = '繼續工作';
      }
    }
  }


  // display job status statistics
  const [statusCount, setStatusCount] = useState({});
  useEffect(() => {
    // check if job is a list and not empty
    if (Array.isArray(jobs) && jobs.length !== 0) {
      let dates = []
      
      for (let i = 0; i < jobs.length; i++) {
        if (!dates.includes(jobs[i].date.substring(0, 10))) {
          dates.push(jobs[i].date.substring(0, 10));
        }
      }
      
      // post request to get status count
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/status-statistic';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify({
          "date": dates,
          "organizationID": localStorage.getItem('organizationId'),
          "type": "createdAt",
        })
      })
        .then(response => response.json())
        .then(data => {
          setStatusCount(data['statistic']);
        })
        .catch(error => console.log(error));
    }
  }, [jobs])

  // estimatedInput focus state
  const [estimatedInputBlur, setEstimatedInputBlur] = useState(true);

  // get user display setting 
  const [dateSort, setDateSort] = useState("");
  const [dateSortText, setDateSortText] = useState("接單日期");
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/user-job-list-display-setting?userID=' + localStorage.getItem("userid");
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData['displaySetting'];
        
        // check if dateSort is in data
        if ('dateSort' in data) {
          setDateSort(data['dateSort']);

          if (data['dateSort'] === "meta.estimatedDate") {
            setDateSortText("預計工作日期");
          } else if (data['dateSort'] === "latestStatus.changedAt") {
            setDateSortText("最新狀態日期");
          } else {
            setDateSortText("接單日期");
          }
        }
      })
      .catch(error => console.log(error))
  }, [])

  // handle filter display button 
  const filterDisplayRef = useRef(null);
  const filterDisplayBtnIconRef = useRef(null);
  const handleFilterDisplay = (e) => {
    e.preventDefault();

    if (filterDisplayRef.current) {
      // check if it's hidden. If yes, show it
      if (filterDisplayRef.current.classList.contains('hidden')) {
        filterDisplayRef.current.classList.remove('hidden');

        // change icon to up
        filterDisplayBtnIconRef.current.classList.add('transform', 'rotate-180');

      } else {
        filterDisplayRef.current.classList.add('hidden');

        // change icon to down
        filterDisplayBtnIconRef.current.classList.remove('transform', 'rotate-180');

      }
    }
  }

  // handle batch change
  const handleBatchChange = (e) => {
    let jobID = e.target.getAttribute('data-job-id');
    let isSelected = e.target.checked;

    if (isSelected) {
      // check if the selected status is the same as the first selected status. If not, warn user
      if (batchSelectedStatus === "" || batchSelectedJobs.length === 0) {
        setBatchSelectedStatus(e.target.getAttribute('data-status'));
      } else {
        if (batchSelectedStatus !== e.target.getAttribute('data-status')) {
          alert("請選擇相同的工作狀態");
          e.target.checked = false;
          return;
        }
      }

      setBatchSelectedJobs(prevState => [...prevState, jobID]);
    } else {
      setBatchSelectedJobs(prevState => prevState.filter(item => item !== jobID));
    }

  }

  // job priority
  const invPriorityMapping = { 0: '無', 1: '低', 2: '中', 3: '高' };

  // handle show all filter which display hidden filter to main display container
  const [showAllFilter, setShowAllFilter] = useState(false);
  useEffect(() => {
    // set initial showAllFilter state by checking local storage
    if (localStorage.getItem('showAllFilter') === 'true') {
      setShowAllFilter(true);
    }
  }, [])

  // set local storage when showAllFilter state changes
  useEffect(() => {
    localStorage.setItem('showAllFilter', showAllFilter);
  }, [showAllFilter])

  // loading indicator
  const loadingIndicatorRef = useRef(null);
  const [isIndicatorInView, setIsIndicatorInView] = useState(false);
  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              const isVisible = entry.isIntersecting;
              // console.log('IntersectionObserver entry:', entry); // Debugging log
              setTimeout(() => setIsIndicatorInView(isVisible), 100); // 100ms debounce
          },
          {
              root: null,
              rootMargin: '0px',
              threshold: 0.1,
          }
      );

      const element = loadingIndicatorRef.current;
      if (element) {
          // console.log('Observing element:', element); // Debugging log
          observer.observe(element);
      } 

      return () => {
          if (element) observer.unobserve(element);
      };
  }, [loadingIndicatorRef.current]);

  // add to numJobDisplay when indicator is in view
  useEffect(() => {
    if (isIndicatorInView && (localStorage.getItem("jobListPreference_secondaryScreen") === "true" || localStorage.getItem("fixedChatInterface") === "true")) {
      // console.log("Indicator in view, adding 10 jobs");
      setIsLoadingNewData(true);
      setNumJobDisplay(prevNumJobDisplay => prevNumJobDisplay + 20);
    }
  }, [isIndicatorInView]);

  // job view type
  const [jobViewType, setJobViewType] = useState("stacked");
  useEffect(() => {  // set job view type when component is mounted
    setJobViewType(localStorage.getItem("jobListPreference_viewMode") || "stacked");
  }, [])

  return (
    <div
      className="overflow-y-hidden"
    >
      <div id="header-bar">

        <div className="flex flex-wrap">
            <div
              className="flex items-center justify-between w-full"
            >
              <button 
                type="button"
                onClick = {handleFilterDisplay}
                className='sm:hidden w-full whitespace-nowrap inline-flex items-center justify-between rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2'
              > 
                <span 
                  className='flex items-center gap-x-1'
                >
                  <FunnelIcon className="h-5 w-5 mr-1" aria-hidden="true" />篩選
                </span>
                <span
                  ref={filterDisplayBtnIconRef}

                >
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </button>
              <button 
                  type="button"
                  onClick={() => {
                    setSelectedLoc({
                      "name": "所有地點"
                    });
                    setSelectedStaff({
                      "name": "所有員工"
                  });
                    setDateFilter(null);
                    setEstimateDate("");
                    setSelectedTaskType({"name": "所有類型"});
                    setSelectedTask({"name": "所有要求"});
                    setFilterStatus({"status": "所有工作", "color": "white"})
                  }}
                  className="whitespace-nowrap items-center justify-center rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:hidden inline-flex " 
                >
                  重設
                </button>
            </div>
          <div
            className="hidden sm:flex flex-wrap w-full"
            ref={filterDisplayRef}
          >
            <StatusFilter 
              statusList={statusList}  
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
            />
            {/* Location Filter */}
            <LargeListFilterComponent 
              data={locList}
              filter={selectedLoc}
              setFilter={setSelectedLoc}
            />
            {/* Staff Filter */}
            <FilterComponent
              data={staffList}
              filter={selectedStaff}
              setFilter={setSelectedStaff}
            />
            <div
              className="sm:hidden"
            >
              {/* Type Filter */}
              <FilterComponent
                  data={typeList}
                  filter={selectedTaskType}
                  setFilter={setSelectedTaskType}
                />
                {/* service item filter */}
                <FilterComponent
                  data={taskList}
                  filter={selectedTask}
                  setFilter={setSelectedTask}
                />
                {/* Created Date Filter */}
                <div className='relative mt-1 sm:mr-2' id="datefilter-wrapper">
                  <Datepicker
                    showShortcuts={true}
                    value={dateFilter}
                    onChange={(date) => setDateFilter(date)}
                    placeholder='選擇接單日期'
                    inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                    configs={{
                        shortcuts: {
                          today: "今天",
                          yesterday: "昨日", 
                          past: (period) => `過去 ${period} 天`,
                          currentMonth: "本月",
                          pastMonth: "上月",
                        }
                      }} 
                  />
                </div>
                {/* Estimate Date Filter */}
                <div className='relative mt-1 sm:mr-2'>
                  <div className="relative rounded-md shadow-sm">
                    <input 
                      type='text'
                      placeholder='預計工作日期'
                      id="estimateDate"
                      onFocus={(e) => {
                        e.target.type = 'date'
                        setEstimatedInputBlur(false)
                      }}
                      onBlur={(e) => {
                        e.target.type = 'text'
                        setEstimatedInputBlur(true)
                      }}
                      value={estimateDate}
                      onChange={(e) => setEstimateDate(e.target.value)}
                      className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                    />
                    {estimatedInputBlur && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                  </div>
                </div>

            </div>
            
            {/* Task Type Filter */}
            <div className=" mr-1 hidden sm:flex sm:flex-wrap sm:items-center">
              {!showAllFilter ? (
                <div
                  className="mt-1"
                >
                  <FilterContainerDropdown 
                    typeList={typeList}
                  selectedTaskType={selectedTaskType}
                  setSelectedTaskType={setSelectedTaskType}
                  taskList={taskList}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  estimateDate={estimateDate}
                  setEstimateDate={setEstimateDate}
                  estimatedInputBlur={estimatedInputBlur} 
                  setEstimatedInputBlur={setEstimatedInputBlur}
                    setShowAllFilter={setShowAllFilter}
                  />
                </div>
              ) : (
                <div
                  className="flex items-center flex-wrap"
                >
                  <>
                    <FilterComponent
                      data={typeList}
                      filter={selectedTaskType}
                      setFilter={setSelectedTaskType}
                    />
                    {/* service item filter */}
                    <FilterComponent
                      data={taskList}
                      filter={selectedTask}
                      setFilter={setSelectedTask}
                    />
                    {/* Created Date Filter */}
                    <div className='relative mt-1 mr-1' id="datefilter-wrapper">
                      <Datepicker
                        showShortcuts={true}
                        value={dateFilter}
                        onChange={(date) => setDateFilter(date)}
                        placeholder='選擇接單日期'
                        inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                        configs={{
                            shortcuts: {
                              today: "今天",
                              yesterday: "昨日", 
                              past: (period) => `過去 ${period} 天`,
                              currentMonth: "本月",
                              pastMonth: "上月",
                            }
                          }} 
                      />
                    </div>
                    {/* Estimate Date Filter */}
                    <div className='relative mt-1 mr-1'>
                      <div className="relative rounded-md shadow-sm">
                        <input 
                          type='text'
                          placeholder='預計工作日期'
                          id="estimateDate"
                          onFocus={(e) => {
                            e.target.type = 'date'
                            setEstimatedInputBlur(false)
                          }}
                          onBlur={(e) => {
                            e.target.type = 'text'
                            setEstimatedInputBlur(true)
                          }}
                          value={estimateDate}
                          onChange={(e) => setEstimateDate(e.target.value)}
                          className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                        />
                        {estimatedInputBlur && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  <button
                    onClick={() => setShowAllFilter(false)}
                    className="text-sm text-gray-500 hover:text-gray-700 cursor-pointer flex items-center gap-x-1 h-[36px] mx-1 mt-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-md bg-white px-3"
                  > 
                    <EllipsisVerticalIcon className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
            {/* a filter reset button  */}
            <button 
              type="button"
              onClick={() => {
                setSelectedLoc({
                  "name": "所有地點"
                });
                setSelectedStaff({
                  "name": "所有員工"
              });
                setDateFilter(null);
                setEstimateDate("");
                setSelectedTaskType({"name": "所有類型"});
                setSelectedTask({"name": "所有要求"});
                setFilterStatus({"status": "所有工作", "color": "white"})
              }}
              className="whitespace-nowrap items-center justify-center rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hidden sm:inline-flex"
            >
              重設篩選
            </button>
          </div>
        </div>
      </div>
      <ul 
        role="list" 
        className="divide-y divide-gray-100 mt-4"
      >
        {manualRefreshBtnText === "刷新中..." && jobs.length === 0 ? (
          <div className="flex items-center justify-center">
            存取數據中...
            <ArrowPathIcon className="h-5 w-5 animate-spin" aria-hidden="true" />
          </div>
        ) : (
            (!isBatchChange ? 
                (!jobs || jobs.length === 0) ? (
                  <>沒有符合條件的工作, 請重設篩選.</>
                ) : (
                  jobViewType === "stacked" ? (
                    <StackedList
                      jobs={jobs}
                      statusCount={statusCount}
                      selectedJobId={selectedJobId}
                      displaySecondaryScreen={displaySecondaryScreen}
                      setSelectedJobId={setSelectedJobId}
                      dateSortText={dateSortText}
                      invPriorityMapping={invPriorityMapping}
                      statuses={statuses}
                      handleProgressJob={handleProgressJob}
                  />
                  ):(
                    <TableView
                      jobList={jobs}
                      displaySecondaryScreen={displaySecondaryScreen}
                      setSelectedJobId={setSelectedJobId}
                      statusCount={statusCount}
                    />
                  )
                )
               : (
              jobs.map((job) => (
                <>
                  <li key={job._id} className="flex items-center gap-x-6 py-4 px-1 " data-jobid={job._id}>
                    {/* checkbox selector */}
                    <div className="flex items-center gap-x-3">
                      <input 
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        type="checkbox" name="jobSelector" data-job-id={job._id} data-status={job.status['status']} onChange={handleBatchChange}
                      />
                      {/* <label htmlFor={job._id} className="text-sm font-semibold leading-6 text-gray-900">{job.locationName} | {job.typeName}</label> */}
                    </div>

                    {/* job info */}
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3 ">
                        <a className="text-sm font-semibold leading-6 text-gray-900 skip-trans" >{job.locationName} | {job.typeName}</a>
                      </div>
                      <div className="mt-1 sm:flex sm:items-center sm:gap-x-2 text-xs leading-5 text-gray-500 ">
                        <p className="overflow-auto whitespace-nowrap">{dateSortText}: {job.date}</p>
                        <p className="overflow-auto whitespace-nowrap">施工同事: {job.assignedTo}</p>
                      </div>
                    </div>

                    {/* job status */}
                    <div className="flex flex-none items-center gap-x-4">
                      <p
                        className={classNames(
                          statuses[job.status['status']],
                          'rounded-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-sm font-semibold ring-1 ring-inset'
                        )}
                      >
                        {job.status['status']}
                      </p>
                    </div>
                  </li>
                </>
                ))
              )
            )
        )}
      </ul>
      {/* Loading indicator when retrieving more data */}
      {jobs.length > 0 && (
        <div 
            className="flex items-center justify-center py-4 text-sm text-gray-500"
            ref={loadingIndicatorRef}
          >
            <ArrowPathIcon className="h-5 w-5 animate-spin mr-2" aria-hidden="true" />
            載入更多工作...
          </div>
        )}
      {/* <Export 
        exportDialog={exportDialog} 
        setExportDialog={setExportDialog}  
      /> */}
    </div>
  )
}

const statusList = [
  {"status": "所有工作", "color": "white"},
  { "status": "回公司", "color": "yellow" },
  { "status": "新工作", "color": "white" },
  { "status": "待分配", "color": "white" },
  { "status": "待確認", "color": "pink" },
  { "status": "未開始", "color": "gray" },
  { "status": "進行中", "color": "green" },
  { "status": "完成", "color": "orange" },
  { "status": "拒絕", "color": "red" },
  { "status": "取消", "color": "red" },
  { "status": "中斷", "color": "red" },
  { "status": "待續", "color": "yellow" },
  { "status": "待檢查", "color": "blue" },
  { "status": "待批准", "color": "blue" },
  { "status": "延期", "color": "yellow" },
  { "status": "緊急", "color": "red" },
  { "status": "維修中", "color": "yellow" },
  { "status": "需要額外部件", "color": "white" },
  { "status": "需要專家幫助", "color": "white" }
]


function StatusFilter({statusList, filterStatus, setFilterStatus}) {
  const [selected, setSelected] = useState(statusList[0])

  const updateFilterStatus = (status) => {
    // set filter status
    setFilterStatus(status);

    // set item in localStorage
    localStorage.setItem("filterStatus", JSON.stringify(status));

  }

  return (
    <Listbox value={filterStatus} onChange={updateFilterStatus}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">過濾工作狀態</Listbox.Label> */}
          <div className="relative mt-1 sm:mr-2">
            <Listbox.Button className="text-[12px] relative w-full cursor-default rounded-md bg-white h-[36px] pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span
                  aria-label={filterStatus.status}
                  className={classNames(
                    filterStatus.color ? `bg-${filterStatus.color}-400 ring-${filterStatus.color}-500/10` : 'bg-white ring-white-500/10',
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full ring-1 ring-inset'
                  )}
                />
                <span className="ml-3 block truncate text-[12px]">{filterStatus.status}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full min-w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                {statusList.map((item) => (
                  <Listbox.Option
                    key={item.status}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ filterStatus, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              item.color ? `bg-${item.color}-400 ring-${item.color}-500/10` : 'bg-white ring-white-500/10',
                              'inline-block h-2 w-2 flex-shrink-0 rounded-full ring-1 ring-inset'
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(filterStatus ? 'font-semibold' : 'font-normal', 'ml-3 block truncate text-sm')}
                          >
                            {item.status}
                            <span className="sr-only"> is {}</span>
                          </span>
                        </div>

                        {filterStatus ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

function FilterComponent({data, filter, setFilter, width=null}) {
  const [query, setQuery] = useState('')

  const filteredList =
    query === ''
      ? data
      : data.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })

  const handleInputSelection = (event) => {
    // look for input element within the event target
    let inputEl = event.target.closest(".filter-btn").getElementsByTagName("input")[0];
    if (inputEl) {
      inputEl.focus();
      inputEl.select();
    }
  }


  return (
    <Combobox as="div" value={filter} onChange={setFilter} className="skip-trans">
      <div className="relative mt-1 sm:mr-2 filter-btn">
        <ComboboxInput
          className={classNames(
            width ? `w-[${width}]` : 'w-full',
            "text-[12px] rounded-md border-0 bg-white h-[36px] pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item?.name}
          onClick={handleInputSelection}
        />
        <ComboboxButton 
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          onClick={handleInputSelection}
        >
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredList.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full min-w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredList.map((item) => (
              <ComboboxOption
                key={item.name}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}

// large list filter component using react-select and return the selected value as object
function LargeListFilterComponent({data, filter, setFilter}) {
  const [selectedOption, setSelectedOption] = useState(filter);
  const [options, setOptions] = useState();

  useEffect(() => {
    let dataOptions = data.map(item => {
      return {
        value: item,
        label: item.name,
      }
    })

    setOptions(dataOptions);
  }, [data])

  // update selectedOption when filter changes
  useEffect(() => {
    // return if options is null
    if (options === null || options === undefined) {
      return;
    }

    // return if filter is null
    if (filter === null || filter === undefined) {
      setSelectedOption(options[0]);
      return;
    }

    // compare filter with options
    let found = options.find(item => item.label === filter.name);

    if (found) {
      setSelectedOption(found);
    } else {
      setSelectedOption(null);
    }

  }, [filter, options])

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFilter(selectedOption['value']);

  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronUpDownIcon label="ChevruonUpDown" className='h-5 w-5' />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 'initial',
      borderRadius: '0.375rem',
      borderColor: '#d1d5db',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '34px', // Adjust height here
      padding: '0 8px',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '4px', // Adjust padding here
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '4px', // Adjust padding here
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#4f46e5': 'white',
      color: state.isSelected ? 'white': 'black',
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      components={{ DropdownIndicator }}
      styles={customStyles}
      className="text-xs mt-1 sm:mr-2 min-w-[180px] skip-trans shadow-sm"
    />
  ) 
}

// export component
function Export({exportDialog, setExportDialog}) {
  const [selectedExportJobType, setSelectedExportJobType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [exportBtnText, setExportBtnText] = useState("匯出");
  const [exportBtnDisabled, setExportBtnDisabled] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [mainTypeList, setMainTypeList] = useState([]);
  const [selectedMainType, setSelectedMainType] = useState(null);

  // fetch jobType from backend 
  const [jobTypeList, setJobTypeList] = useState([]);
  useEffect(() => {
    fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/jobtypes?organizationID=' + localStorage.getItem('organizationId'), {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData['jobTypes'];
        
        // set name to be langVar.tc
        for (let i = 0; i < data.length; i++) {
          data[i].name = data[i].itemCode.toString() + "-" + data[i].langVar.tc + "(" + data[i].name + ")";
          data[i].label = data[i].itemCode.toString() + "-" + data[i].langVar.tc + "(" + data[i].name + ")";
          data[i].value = data[i]._id;
        }

        // sort data by name
        data.sort((a, b) => a.name.localeCompare(b.name));

        // set jobTypeList
        setJobTypeList(data);
      })
      .catch(error => console.log(error))
  }, [])

  // handle export button click
  const [submittedExport, setSubmittedExport] = useState(false); // true if user has submitted export request
  const [fileAvailable, setFileAvailable] = useState(false); // true if file is available for download
  const [downloadLink, setDownloadLink] = useState(null); // download link for file

  const exportDialogRef = useRef(exportDialog);
  useEffect(() => {
    exportDialogRef.current = exportDialog;
  }, [exportDialog]);

  const handleExport = () => {


    if (submittedExport) {
      return;
    } else {
      setSubmittedExport(true);
    }

    // update button text
    setExportBtnText("匯出中...");

    // update button disabled
    setExportBtnDisabled(true);

    let postJobType = [];
    if (selectedExportJobType && selectedExportJobType.length > 0) {
      postJobType = selectedExportJobType.map(item => item.value);
    }


    // convert selectedStatus to a list of status value
    let postStatus = [];
    if (selectedStatus.length > 0) {
      postStatus = selectedStatus.map(item => item.value);
    }

    let postStaff = []
    if (selectedStaff !== null) {
      postStaff = selectedStaff.map(item => item.value);
    }

    let postMainType = []
    if (selectedMainType !== null) {
      postMainType = selectedMainType.map(item => item.value);
    }

    let submitData = {
      query: {
        date: selectedDate,
        typeID: postJobType,
        statusList: postStatus,
        staffList: postStaff,
        mainTypeID: postMainType
      },     
      organizationID: localStorage.getItem('organizationId')
    }

    console.log(submitData)

    // fetch request to /api/v1/jobs/export
    fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/reports/generate-report-for-request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitData)
    })
      .then(response => response.json())
      .then(rawData => {
        let reportId = rawData['reportId'];

        // set button text
        setExportBtnText("匯出");
        // set button disabled
        setExportBtnDisabled(false);

        var reportInterval = setInterval(() => {
          
          // stop if exportDialog is false, stop interval
          if (!exportDialogRef.current) {
            clearInterval(reportInterval);
            console.log("Export dialog is closed")
            // set button text
            setExportBtnText("匯出");
            // set button disabled
            setExportBtnDisabled(false);

            // reset submitExport
            setSubmittedExport(false);
            return;
          }

          // fetch request to /api/v1/reports to get url link
          fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/reports?reportID=' + reportId)
            .then(response => response.json())
            .then(rawData => {
              let data = rawData['report'];

              // check if url field is available
              if (data['url'] !== undefined) {
                console.log(data['url']);

                // set download link
                setDownloadLink(data['url']);

                // set file available to true
                setFileAvailable(true);

                // clear interval
                clearInterval(reportInterval);
              }
            })
            .catch(error => console.log(error))
        }, 1000);


      })
      .catch(error => {
        console.log(error);
        
        // set button text
        setExportBtnText("匯出");
        // set button disabled
        setExportBtnDisabled(false);

        // reset submitExport
        setSubmittedExport(false);
      })

  };

  // create available status from statusList by converting to a list with label and value and filter out 所有工作 and 回公司
  const [availableStatusList, setAvailableStatusList] = useState([]); // list of available status
  useEffect(() => {
    let availableStatus = statusList.filter(item => item.status !== "所有工作" && item.status !== "回公司");
    let statusListOptions = availableStatus.map(item => {
      return {
        label: item.status,
        value: item.status
      }
    })

    setAvailableStatusList(statusListOptions);
    
  }, [])

  // fetch staff from backend
  useEffect(() => {
    fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + sessionStorage.getItem("organizationId"), {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then((res) => res.json())
      .then((rawData) => {
          let data = rawData['users']

        // set name to be langVar.tc
        for (let i = 0; i < data.length; i++) {
          data[i].label = data[i]['displayName']
          data[i].value = data[i]._id;
        }
        
        // sort data by label
        data.sort((a, b) => a.label.localeCompare(b.label));

        // set staffList
        setStaffList(data);
      })
      .catch(error => console.log(error))
  }, [])

  // fetch main type from backend
  useEffect(() => {
    fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/maintypes?organizationID=' + localStorage.getItem('organizationId'), {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData['types'];

        // set name to be langVar.tc
        for (let i = 0; i < data.length; i++) {
          data[i].label = data[i].langVar.tc;
          data[i].value = data[i]._id;
        }

        // sort data by label
        data.sort((a, b) => a.label.localeCompare(b.label));

        // set mainTypeList
        setMainTypeList(data);

      })
      .catch(error => console.log(error))
  }, [])
      

  // clear filter
  const clearFilter = () => {
    setSelectedDate("");
    setSelectedExportJobType(null);
    setSelectedStatus([]);
    setSelectedStaff(null);
    setSelectedMainType(null);
  }

  return (
    <>
      <div
        className={classNames(
          exportDialog ? 'block' : 'hidden',
          'bg-black fixed left-0 top-0 sm:min-w-[400px] w-full h-full z-50 flex items-center justify-center bg-opacity-50')}
      >
        <div className="bg-white shadow rounded sm:rounded-lg  max-w-[90vw]">
          {submittedExport ? (
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">匯出報表</h3>
              <div className="mt-2 ">
                <p
                  className='mb-2'
                >匯出報表請求已送出</p>
                
                {fileAvailable ? (
                  <>
                    <a 
                    href={downloadLink} 
                    download
                    className="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >按此下載檔案
                    </a>
                  </>
                  ) : (
                    <p>
                      <ArrowPathIcon className="animate-spin h-5 w-5 inline-block" />
                      正在生成報表
                    </p>
                  )}

                <button
                  onClick={() => {
                    setExportDialog(false)
                    setSubmittedExport(false)
                    setFileAvailable(false)
                  }}
                  type="button"
                  className="items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                >
                  關閉
                </button>
              </div>
            </div>
          ) : (
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">匯出報表</h3>
              <div className="mt-2 ">
                {/* sets of filter (e.g. date, mainType, typeID*/}
                <div className="mt-2 sm:grid sm:grid-cols-3 sm:gap-5">
                  <div>
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                      接單日期
                    </label>
                    <div className="mt-2">
                      {/* <select
                        name="date"
                        id="date"
                        value={selectedDate}
                        onChange={(event) => setSelectedDate(event.target.value)}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="">請選擇</option>
                        <option value="today">今天</option>
                        <option value="currentMonth">當前月份</option>
                        <option value="last7Days">最近7天</option>
                        <option value="lastMonth">上個月</option>
                      </select> */}
                      <Datepicker
                        showShortcuts={true}
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        placeholder='選擇日期'
                        inputClassName="text-sm rounded dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full"
                      />
                    </div>
                  </div>
                  {/* main type filter */}
                  <div
                    className='mt-4 sm:mt-0'
                  >
                    <label htmlFor="mainType" className="block text-sm font-medium text-gray-700">
                      工作類型
                    </label>
                    <div className="mt-2">
                      <Select
                        options={mainTypeList}
                        closeMenuOnSelect={false}
                        onChange={(selectedOption) => setSelectedMainType(selectedOption)}
                        value={selectedMainType}
                        isMulti
                      />
                    </div>
                  </div>
                  {/* job type filter */}
                  <div
                    className='mt-4 sm:mt-0'
                  >
                    <label htmlFor="mainType" className="block text-sm font-medium text-gray-700">
                      工作要求
                    </label>
                    <div className="mt-2">
                      {/* <SearchableMenu 
                        list={jobTypeList}
                        selectedItem={selectedExportJobType}
                        setSelectedItem={setSelectedExportJobType}
                        /> */}
                        <Select
                          options={jobTypeList}
                          closeMenuOnSelect={false}
                          onChange={(selectedOption) => setSelectedExportJobType(selectedOption)}
                          value={selectedExportJobType}
                          isMulti
                        />
                    </div>
                  </div>
                  {/* a staff list using react-select */}
                  <div className="mt-4 sm:mt-0 sm:col-span-2 ">
                    <label htmlFor="staff" className="block text-sm font-medium text-gray-700">
                      施工同事
                    </label>
                    <div className="mt-2">
                      <Select
                        options={staffList}
                        closeMenuOnSelect={false}
                        onChange={(selectedOption) => setSelectedStaff(selectedOption)}
                        value={selectedStaff}
                        isMulti
                      />
                    </div>
                  </div>
                  {/* job status filter using react-select */}
                  <div className="mt-4 sm:mt-0">
                    <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                      工作狀態
                    </label>
                    <div className="mt-2">
                      <Select
                        options={availableStatusList}
                        closeMenuOnSelect={false}
                        onChange={(selectedOption) => setSelectedStatus(selectedOption)}
                        isMulti
                        value={selectedStatus}
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="mt-5">
                  <button
                    onClick={clearFilter}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                  >
                    清除篩選
                  </button>
                </div>
                <div className="mt-5">
                  <button
                    onClick={() => setExportDialog(false)}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 mr-3"
                  >
                    取消
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={handleExport}
                    disabled={exportBtnDisabled}
                  >
                    {exportBtnText}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

// filter container dropdown
function FilterContainerDropdown({
  typeList,
  selectedTaskType,
  setSelectedTaskType,
  taskList,
  selectedTask,
  setSelectedTask,
  dateFilter,
  setDateFilter,
  estimateDate,
  setEstimateDate,
  estimatedInputBlur,
  setEstimatedInputBlur,
  setShowAllFilter,
}) {

    // check if the menu is overflowing. set left-0 to be right-0 if it is
    const [menuLeft, setMenuLeft] = useState('left-0');
    const menuRef = useRef(null);
    useEffect(() => {
      if (menuRef.current) {
        if (menuLeft == 'left-0' && menuRef.current.getBoundingClientRect().right > window.innerWidth) {
          setMenuLeft('right-0');
        } else if (menuLeft == 'right-0' && menuRef.current.getBoundingClientRect().left < 0) {
          setMenuLeft('left-0');
        }
      }
    }, [menuRef.current]);


    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            其他篩選
            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className={`absolute ${menuLeft} z-10 mt-2 w-[50vw] max-w-[400px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
          ref={menuRef}
        >
          <div className="p-4">
            <div
              className="flex justify-between items-center mb-2"
            >
              <h3 className="text-sm font-semibold">篩選</h3>
              <button
                onClick={() => setShowAllFilter(true)}
                className="text-sm text-gray-500 hover:text-gray-700 cursor-pointer flex items-center gap-x-1"
              > 
                <EllipsisVerticalIcon className="h-5 w-5" />
                顯示全部篩選
              </button>
            </div>
            {/* Type Filter */}
            <FilterComponent
                data={typeList}
                filter={selectedTaskType}
                setFilter={setSelectedTaskType}
              />
              {/* service item filter */}
              <FilterComponent
                data={taskList}
                filter={selectedTask}
                setFilter={setSelectedTask}
              />
              {/* Created Date Filter */}
              <div className='relative mt-1 mr-1' id="datefilter-wrapper">
                <Datepicker
                  showShortcuts={true}
                  value={dateFilter}
                  onChange={(date) => setDateFilter(date)}
                  placeholder='選擇接單日期'
                  inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                  configs={{
                      shortcuts: {
                        today: "今天",
                        yesterday: "昨日", 
                        past: (period) => `過去 ${period} 天`,
                        currentMonth: "本月",
                        pastMonth: "上月",
                      }
                    }} 
                />
              </div>
              {/* Estimate Date Filter */}
              <div className='relative mt-1 mr-1'>
                <div className="relative rounded-md shadow-sm">
                  <input 
                    type='text'
                    placeholder='預計工作日期'
                    id="estimateDate"
                    onFocus={(e) => {
                      e.target.type = 'date'
                      setEstimatedInputBlur(false)
                    }}
                    onBlur={(e) => {
                      e.target.type = 'text'
                      setEstimatedInputBlur(true)
                    }}
                    value={estimateDate}
                    onChange={(e) => setEstimateDate(e.target.value)}
                    className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                  />
                  {estimatedInputBlur && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
              </div>
          </div>
        </MenuItems>
      </Menu>
    )
}
