import { 
    ChevronRightIcon,
    XMarkIcon
} from '@heroicons/react/20/solid'
import {useEffect, useState} from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import GlobalVars from '../../../../Config'

// import sub modules setting components
import MinibarPosting from '../SubModules/MinibarPosting'
import ChecklistSetting from '../ChecklistSetting'

export default function ModuleSettings() {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [selectedModule, setSelectedModule] = useState(null)
    return (
        <div
            className="px-4 py-5 sm:p-6"
        >
            <h3 className="text-base font-semibold leading-6 text-gray-900">模組設定</h3>
            <p
                className="mt-1 text-sm leading-5 text-gray-500"
            >
                這裡可以設定模組的相關設定
            </p>
            <ModuleList 
                setOpenDrawer={setOpenDrawer}
                setSelectedModule={setSelectedModule}
            />
            <ModuleSettingsDrawer 
                open={openDrawer}
                setOpen={setOpenDrawer}
                selectedModule={selectedModule}
            />
        </div>
    )
}


function ModuleList({setOpenDrawer, setSelectedModule}) {
    const [moduleList, setModuleList] = useState([])
    useEffect(() => {
        // query available modules for the organization
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/submodules' + '?organizationID=' + localStorage.getItem('organizationId')
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data)
            setModuleList(data)
        })
        .catch((error) => {
            console.error('Error:', error)
        })
    }, [])


    return (
        <ul role="list" className="divide-y divide-gray-100">
            {moduleList.length > 0 ? (
                moduleList.map((item) => (
                    <li key={item.email} className="relative py-5 hover:bg-gray-50">
                    <div className="px-4 ">
                        <div className="mx-auto flex justify-between gap-x-6">
                        <div className="flex min-w-0 gap-x-4">
                            {/* <img alt="" src={item.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                    <a 
                                        onClick={() => {
                                            setOpenDrawer(true)
                                            setSelectedModule(item.name)
                                        }}
                                        className='cursor-pointer'
                                    >
                                        <span className="absolute inset-x-0 -top-px bottom-0" />
                                        {item.langVar.tc}
                                    </a>
                                </p>
                                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                    {/* <a href={`mailto:${item.email}`} className="relative truncate hover:underline">
                                    {item.email}
                                    </a> */}
                                </p>
                            </div>
                        </div>
                        <div className="flex shrink-0 items-center gap-x-4">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                            </div>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                        </div>
                        </div>
                    </div>
                    </li>
                ))
            ) : (
                <li className="mt-3">
                    <p className="text-sm text-gray-500">暫時沒有模組</p>
                </li>
            )}
        </ul>
    )
}

function ModuleSettingsDrawer({open, setOpen, selectedModule}) {
    useEffect(() => {
        console.log(selectedModule);
        if (selectedModule === "Minibar Posting") {
            setDisplayTitle("報Bar設定");
        } else if (selectedModule === "Checklist") {
            setDisplayTitle("清單設定");
        }
    }, [selectedModule]);

    const [displayTitle, setDisplayTitle] = useState("設定");

    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        if (!isSaving) {
            setOpen(false);
        }
    }, [isSaving]);

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
          <div className="fixed inset-0" />
    
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <DialogPanel
                  transition
                  className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                >
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                            {displayTitle}
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              onClick={() => setOpen(false)}
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {selectedModule === "Minibar Posting" && <MinibarPosting />}
                        {selectedModule === "Checklist" && <ChecklistSetting 
                          isSaving={isSaving}
                          setIsSaving={setIsSaving}
                        />}
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      >
                        取消
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={() => setIsSaving(true)}
                      >
                        儲存
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </div>
            </div>
          </div>
        </Dialog>
      )
}
