import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";
import { ClockIcon } from "@heroicons/react/24/outline";

export default function JobDetailSecondaryScreen({
    selectedJobId,
}) {
    const [jobDetail, setJobDetail] = useState(null);
    useEffect(() => {
        // return if selectedJobId is not provided
        if (!selectedJobId) {
            return;
        }

        // fetch job detail
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + selectedJobId, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        })
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to fetch job detail");
            }
        })
        .then(data => {
            setJobDetail(data['job']);
        })
        .catch(err => {
            console.log(err);
        });
    }, [selectedJobId]);

    return (
        <>
            {selectedJobId ? (
                <div
                    className="h-full w-full overflow-y-auto"
                >
                {jobDetail && (
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="pb-5 sm:pb-6 flex justify-between items-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">工作詳情</h3>
                            <a
                                href={`/jobs/${selectedJobId}`}
                                className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <ClockIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                查看工作詳情
                            </a>
                        </div>
                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">工作地點</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {jobDetail.locationName && jobDetail.locationName.map(loc => loc.name).join(', ')}
                                    </dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">工作類型</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {jobDetail.typeName && jobDetail.typeName.join(', ')}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">施工人員</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{jobDetail.assignedToName}</dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">接單人員</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{jobDetail.assignedByName}</dd>
                                </div>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">客戶要求</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{jobDetail.meta && jobDetail.meta.clientRemark || "無"}</dd>
                                </div>
                                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">工作備註</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {/* Add job remark here if available in the jobDetail object */}
                                        {jobDetail.jobRemark || "無"}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        {/* Status Timeline Section */}
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">狀態時間線</h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <div className="flow-root">
                                <ul role="list" className="-mb-8">
                                    {jobDetail.status && jobDetail.status.map((statusChange, idx) => (
                                        <li key={statusChange.changedAt}>
                                            <div className="relative pb-8">
                                                {idx !== jobDetail.status.length - 1 ? (
                                                    <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                ) : null}
                                                <div className="relative flex space-x-3">
                                                    <div>
                                                        <span className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${GlobalVars.statuses[statusChange.status]}`}>
                                                            <ClockIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
                                                        </span>
                                                    </div>
                                                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                        <div>
                                                            <p className="text-sm text-gray-500">
                                                                <span className="font-medium text-gray-900">{statusChange.status}</span>
                                                            </p>
                                                        </div>
                                                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                            {new Date(statusChange.changedAt).toLocaleString('zh-HK')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                )}
                </div>
            ) : (
                <div className="flex items-center justify-center h-full w-full">
                    <h1 className="text-gray-500">請選擇工作</h1>
                </div>
            )}
        </>
    )
}