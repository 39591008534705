// Note: For some reasons, react-datasheet-grid need to use react-virtual 3.0.4 so if you receives react_virtual_1.useVirtualizer error. Check react-virtual versions!

import React,{ Fragment, useEffect, useState, useLayoutEffect, useRef } from 'react'
import { Dialog, Transition, Popover, DialogTitle, DialogPanel } from '@headlessui/react'
import {
    DataSheetGrid,
    keyColumn,
    checkboxColumn,
    textColumn,
    createAddRowsComponent,
    createContextMenuComponent,
    intColumn,
} from 'react-datasheet-grid';
import Select, {createFilter} from 'react-select';
import GlobalVar from '../../Config';
import {MultiSelect} from "react-multi-select-component";
import {
    XMarkIcon,
} from '@heroicons/react/24/outline'
import 'react-datasheet-grid/dist/style.css'
import './MultiJobForm.css'


const jobStatus = {
    "新工作": "New Job",
    "待確認": "Awaiting Confirmation",
    "未開始": "Not Started",
    "進行中": "In Progress",
    "中斷": "Interrupted",
    "待續": "Temporarily Suspended",
    "待檢查": "Awaiting Inspection",
    "待批准": "Awaiting Approval",
    "完成": "Completed",
    "拒絕": "Rejected",
    "取消": "Cancelled",
    "延期": "Postponed",
    "回公司": "Returned to Office",
    "緊急": "Emergency",
    "維修中": "Under Repair",
    "需要額外部件": "Additional Parts Required",
    "需要專家幫助": "Expert Assistance Required"
  };

export default function MultiJobForm({ state, setEditState }) {

    const [ data, setData ] = useState([{
        assignedTo: '',
        typeID: '',
        locationID: '',
    },
    {
        assignedTo: '',
        typeID: '',
        locationID: '',
    },
    {
        assignedTo: '',
        typeID: '',
        locationID: '',
    }
    ])

    const [jobReminderList, setJobReminderList] = useState([{"name": "", "targets": []}]);
    const [selectedEstimatedDate, setSelectedEstimatedDate] = useState(null);

    const [submitBtnText, setSubmitBtnText] = useState('儲存');
    // set useRef for submit button
    const submitBtnRef = useRef(null);
    

    const submitForm = () => {
        setSubmitBtnText('儲存中...');
        // disable the button 
        submitBtnRef.current.disabled = true;

        let jobData = data;

        // remove empty rows
        jobData = jobData.filter((row) => {
            return Object.keys(row).length > 1;
        })

        // iteratively remove object in jobData where typeID and locationID are empty string
        jobData = jobData.filter((row) => {
            return row.typeID !== '' && row.locationID !== '';
        })


        // return if there's no data
        if (jobData.length === 0) {
            alert("請至少填寫一個工作")

            setSubmitBtnText('儲存');
            submitBtnRef.current.disabled = false;
            return;
        }

        // iterate through each row 
        for (let i = 0; i < jobData.length; i++) {
            let row = jobData[i];

            // check if  jobType, location are filled
            if (!row.hasOwnProperty('typeID') || !row.hasOwnProperty('locationID')) {
                alert('第' + (i + 1) + '行有未填寫的欄位 (工作類別, 地點)');

                setSubmitBtnText('儲存');
                submitBtnRef.current.disabled = false;
                return;
            }

        }        

        // create submitData for job creation
        let submitData = {
            organizationID: sessionStorage.getItem('organizationId'),
            jobs: jobData,
            assignedBy: localStorage.getItem('userid'),
            statusNotificationList: jobReminderList,
            estimatedDate: selectedEstimatedDate,
        }


        if (!window.confirm('確定新增工作? 準備新增' + jobData.length + '個工作')) {
            setSubmitBtnText('儲存');
            submitBtnRef.current.disabled = false;
            return;
        }

        // send request to backend
        let url = GlobalVar.BACKEND_DOMAIN + '/api/v1/jobs';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('idToken')
            },
            body: JSON.stringify(submitData),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // alert success
                alert('成功新增工作');
                // close form
                setEditState(false);
                // reload page
                window.location.reload();
            })
            .catch((error) => {
                console.error('Error:', error);
                setSubmitBtnText('儲存');
                submitBtnRef.current.disabled = false;
            });

    };

    // store current jobs as templates
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const storeAsTemplate = () => {
        // count valid rows where typeID and locationID are not empty
        let validRows = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].typeID !== '' && data[i].locationID !== '') {
                validRows++;
            }
        }

        // return if there's no data
        if (validRows === 0) {
            alert("請至少填寫一個工作")
            return;
        }

        // remove rows with empty typeID and locationID
        let dataCopy = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].hasOwnProperty('typeID') && data[i].typeID !== '' && data[i].hasOwnProperty('locationID') && data[i].locationID !== '') {
                dataCopy.push(data[i]);
            }
        }

        // check if users have selected a template
        let updateMethod = 'POST';
        let templateName;
        let submitBody = {};
        if (selectedTemplate != null) {
            // prompt users if they want to update the template
            if (window.confirm('確定更新現有模板?\n模板名稱: ' + selectedTemplate.templateName + "\n選擇否將會新增一個新模板")) {
                updateMethod = 'PUT';
            } else {
                return
            }

            submitBody = selectedTemplate;
            submitBody['jobs'] = dataCopy;
        } else {
            // prompt users to confirm how many jobs they want to store as template
            if (!window.confirm('確定將' + validRows + '個工作存為模板?')) {
                return;
            }

            // prompt users for a template name
            templateName = prompt('請輸入模板名稱');  
            
            submitBody = {
                organizationID: sessionStorage.getItem('organizationId'),
                jobs: dataCopy,
                createdBy: localStorage.getItem('userid'),
                templateName: templateName,
            }
        }

        console.log(submitBody)

        // create fetch request to /api/v1/job/manage-templates
        let url = GlobalVar.BACKEND_DOMAIN + '/api/v1/job/manage-templates';
        fetch(url, {
            method: updateMethod,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('idToken')
            },
            body: JSON.stringify(submitBody),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // alert success
                alert('成功存為模板');
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('存為模板失敗');
            });
        


    }

    return (
        <>
            <Dialog open={state} onClose={setEditState} className="relative z-50 dynamic-comp">
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:max-w-none ">
                        <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-6xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                        >
                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                <DialogTitle className="text-base font-semibold leading-6 text-gray-900">新增多個工作</DialogTitle>
                                <div className="ml-3 flex h-7 items-center">
                                    <button
                                    type="button"
                                    onClick={() => setEditState(false)}
                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                <FormContent 
                                    data={data} 
                                    setData={setData} 
                                    jobReminderList={jobReminderList} 
                                    setJobReminderList={setJobReminderList}
                                    selectedEstimatedDate={selectedEstimatedDate}
                                    setSelectedEstimatedDate={setSelectedEstimatedDate}  
                                    setSelectedTemplate={setSelectedTemplate}                                      
                                />
                            </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4 pb-10 md:pb-4 absolute bottom-0 w-full bg-white">
                                <button
                                    type="button"
                                    id="dismiss-button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={() => setEditState(false)}
                                >
                                    取消
                                </button>
                                <button
                                    type="button"
                                    id="dismiss-button"
                                    className="ml-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={storeAsTemplate}
                                >
                                    另存為模板
                                </button>
                                <button
                                    type="button"
                                    id="submit-button"
                                    onClick={submitForm}
                                    ref={submitBtnRef}
                                    className="ml-2 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                    {submitBtnText}
                                </button>
                            </div>
                        </div>
                        </DialogPanel>
                    </div>
                    </div>
                </div>
                </Dialog>
        </>
    )
}

// Form Content
function FormContent({
    data, 
    setData, 
    jobReminderList, 
    setJobReminderList, 
    selectedEstimatedDate, 
    setSelectedEstimatedDate, 
    setSelectedTemplate
}) {
    // set up choices for location selection
    const [locChoices, setLocChoices] = useState([]);
    useEffect(() => {
        // fetch existing locations for the organization
        let locUrl = GlobalVar.BACKEND_DOMAIN + '/api/v1/locations?organizationID=' + sessionStorage.getItem('organizationId') + '&status=active';
        fetch(locUrl,{
            headers: {
              'Authorization': sessionStorage.getItem('idToken')
            }
          })
            .then(response => response.json())
            .then(rawData => {
                let data = rawData['locations'];
                data = data.filter(item => item["isActive"] !== false);
                // set up choices
                let choices = data.map((loc) => {
                    let tc = loc.langVar?.tc || ""; // Use an empty string as the default value
                    return {value: loc._id, label: loc.name + " (" + tc + ")"};
                });
                
                setLocChoices(choices);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    // on-demand data validation
    const [editedRow, setEditedRow] = useState(null);
    useEffect(() => {   
        let currentRow = editedRow - 1;
        console.log(currentRow)
        // check for duplicates based on assignedTo, typeID, locationID of that row
        if (currentRow != null && data.length != 0 && currentRow >= 0) {
            // check if length of data is less than currentRow
            if (data.length < currentRow) {
                return;
            }

            // check jobPriority inputs 
            if (data[currentRow].hasOwnProperty('jobPriority')) {
                if (data[currentRow].jobPriority < 0 || data[currentRow].jobPriority > 3) {
                    alert('第' + (currentRow + 1) + '行的工作優先必須在0-3之間');
                }
            }

            // check if assignedTo, typeID, locationID keys exist
            if (!data[currentRow].hasOwnProperty('assignedTo') || !data[currentRow].hasOwnProperty('typeID') || !data[currentRow].hasOwnProperty('locationID')) {
                return;
            }

            // if any of the three keys are empty, return
            if (data[currentRow].assignedTo === '' || data[currentRow].typeID === '' || data[currentRow].locationID === '') {
                return;
            }

            // let currentAssignedTo = data[currentRow].assignedTo;
            // let currentTypeID = data[currentRow].typeID;
            // let currentLocationID = data[currentRow].locationID;

            // create a fetch request to check for duplicates in the backend
            // let url = GlobalVar.BACKEND_DOMAIN + '/api/v1/jobs/check-duplication';
            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         "locationID": currentLocationID,
            //         "typeID": currentTypeID,
            //         "assignedTo": currentAssignedTo,
            //         "estimatedDate": selectedEstimatedDate,
            //         "organizationID": sessionStorage.getItem('organizationId'),
            //     }),
            // })
            //     .then(response => {
            //         if (response.status === 200) {
            //             alert("第" + (currentRow + 1) + "行的工作已存在並派發給相同的員工, 工作類別和地點, 請檢查並更改");
            //         } else {
            //             return new Error('Error');
            //         }
            //     })
            //     .catch((error) => {
            //         console.error('Error:', error);
            //     });
            
            // // check for duplicates in the current data
            // for (let i = 0; i < data.length; i++) {
            //     if (i === currentRow) {
            //         continue;
            //     }

            //     if (data[i].assignedTo === currentAssignedTo && data[i].typeID === currentTypeID && data[i].locationID === currentLocationID) {
            //         alert('第' + (currentRow + 1) + '行和第' + (i + 1) + '行有重複的員工名稱, 工作類別和地點');
            //         return;
            //     }
            // }
            
        }


    }, [data])
   
    // set up select component for locations
    const LocSelectComponent = (CellProps) => {
        

        const colKey = 'locationID';

        var active = CellProps.active;
        var focus = CellProps.focus;
        var rowData = CellProps.rowData;
        var setRowData = CellProps.setRowData;
        var stopEditing = CellProps.stopEditing;

        // set up ref 
        const ref = useRef(<Select />);

        // useLayoutEffect to focus on select when focus
        useLayoutEffect(() => {
            if (focus) {
                ref.current.focus();
            } else {
                ref.current.blur();
            }
        }, [focus]);
        
        return (
        <Select 
            ref={ref}
            styles={{
                
                container: (provided) => ({
                    ...provided,
                    flex: 1, // full width
                    alignSelf: 'stretch', // full height
                    pointerEvents: focus ? undefined : 'none',
                }),
                control: (provided) => ({
                    ...provided,
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'none',
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
                placeholder: (provided) => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    fontSize: '13px'
                })
            }}
            filterOption={createFilter({
                matchFrom: 'any',
                stringify: option => `${option.label}`,
              })}
            options={locChoices}
            menuIsOpen={focus}
            menuPortalTarget={document.body}
            classNamePrefix={"multi-job-form-loc-select"}
            value={
                locChoices.find(({value}) => value === rowData[colKey]) ?? null
            }
            onChange={({ value }) => {
                // get current key and set new value to rowData
                let newData = rowData;
                newData[colKey] = value;
                setRowData(newData)
                // We don't just do `stopEditing()` because it is triggered too early by react-select
                setTimeout(stopEditing, 0)
            }}
        />
        )
    }

    // set up choices for job type selection
    const [jobTypeChoices, setJobTypeChoices] = useState([]);

    useEffect(() => {
        let jobTypeUrl = GlobalVar.BACKEND_DOMAIN + '/api/v1/jobtypes?organizationID=' + sessionStorage.getItem('organizationId') + "&status=active";
        fetch(jobTypeUrl,{
            headers: {
              'Authorization': sessionStorage.getItem('idToken')
            }
          })
            .then(response => response.json())
            .then(rawData => {
                // return error if jobTypes key doesn't exist all the length of value is zero
                if (!rawData.hasOwnProperty('jobTypes') || rawData['jobTypes'].length === 0) {
                    return new Error('No job types found');
                }

                let data = rawData['jobTypes'];
                // set up choices
                let choices = data.map((type) => {
                    if (type.hasOwnProperty('langVar')) {
                        return {value: type._id, label: type.itemCode + "-" + type.langVar.tc + "(" + type.name + ")"}
                    }
                    return {value: type._id, label: type.itemCode + "-" + type.name}
                })

                // sort ascentindg by label 
                choices.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                })

                setJobTypeChoices(choices);
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])
   
    // set up select component for job types
    const JobTypeSelectComponent = (CellProps) => {
        
        const colKey = 'typeID';

        var active = CellProps.active;
        var focus = CellProps.focus;
        var rowData = CellProps.rowData;
        var setRowData = CellProps.setRowData;
        var stopEditing = CellProps.stopEditing;

        // set up ref 
        const ref = useRef(<Select />);

        // useLayoutEffect to focus on select when focus
        useLayoutEffect(() => {
            if (focus) {
                ref.current.focus();
            } else {
                ref.current.blur();
            }
        }, [focus]);

        return (
        <Select 
            ref={ref}
            styles={{
                
                container: (provided) => ({
                    ...provided,
                    flex: 1, // full width
                    alignSelf: 'stretch', // full height
                    pointerEvents: focus ? undefined : 'none',
                }),
                control: (provided) => ({
                    ...provided,
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'none',
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
                placeholder: (provided) => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
                menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    fontSize: '13px'
                })
            }}
            options={jobTypeChoices}
            menuIsOpen={focus}
            menuPortalTarget={document.body}
            classNamePrefix={"multi-job-form-loc-select"}
            value={
                jobTypeChoices.find(({value}) => value === rowData[colKey]) ?? null
            }
            onChange={({ value }) => {
                // get current key and set new value to rowData
                let newData = rowData;
                newData[colKey] = value;
                setRowData(newData)
                // We don't just do `stopEditing()` because it is triggered too early by react-select
                setTimeout(stopEditing, 0)
            }}
        />
        )
    }

    // set up choices for staff selection
    const [staffChoices, setStaffChoices] = useState([]);

    useEffect(() => {
        let staffUrl = GlobalVar.BACKEND_DOMAIN + '/api/v1/users?organizationID=' + sessionStorage.getItem('organizationId');
        fetch(staffUrl,{
            headers: {
              'Authorization': sessionStorage.getItem('idToken')
            }
          })
            .then(response => response.json())
            .then(rawData => {
                // return error if staffs key doesn't exist all the length of value is zero
                if (!rawData.hasOwnProperty('users') || rawData['users'].length === 0) {
                    return new Error('No staffs found');
                }

                let data = rawData['users'];
                
                // set up choices
                let choices = data
                    .filter(staff => staff.status !== 'inactive') // Filter out staff with status "inactive"
                    .map((staff) => {
                        return {value: staff._id, label: staff.displayName}
                    });

                setStaffChoices(choices);
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    // set up select component for staff
    const StaffSelectComponent = (CellProps) => {
            
            const colKey = 'assignedTo';
    
            var active = CellProps.active;
            var focus = CellProps.focus;
            var rowData = CellProps.rowData;
            var setRowData = CellProps.setRowData;
            var stopEditing = CellProps.stopEditing;
    
            // set up ref 
            const ref = useRef(<Select />);
    
            // useLayoutEffect to focus on select when focus
            useLayoutEffect(() => {
                if (focus) {
                    ref.current.focus();
                } else {
                    ref.current.blur();
                }
            }, [focus]);
    
            return (
            <Select 
                ref={ref}
                styles={{
                    
                    container: (provided) => ({
                        ...provided,
                        flex: 1, // full width
                        alignSelf: 'stretch', // full height
                        pointerEvents: focus ? undefined : 'none',
                    }),
                    control: (provided) => ({
                        ...provided,
                        height: '100%',
                        border: 'none',
                        boxShadow: 'none',
                        background: 'none',
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        opacity: active ? 1 : 0,
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        opacity: active ? 1 : 0,
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                        fontSize: '13px'
                    })
                }}
                options={staffChoices}
                menuIsOpen={focus}
                menuPortalTarget={document.body}
                classNamePrefix={"multi-job-form-loc-select"}
                value={
                    staffChoices.find(({value}) => value === rowData[colKey]) ?? null
                }
                onChange={({ value }) => {
                    // get current key and set new value to rowData
                    let newData = rowData;
                    newData[colKey] = value;
                    setRowData(newData)
                    // We don't just do `stopEditing()` because it is triggered too early by react-select
                    setTimeout(stopEditing, 0)
                }}
            />
            )
        }


    // set up columns
    const columns = [
        // { ...keyColumn('staffName', textColumn), title: '員工名稱' },
        {component: StaffSelectComponent, title: '員工名稱', disableKeys: true, key: 'assignedTo', 
            deleteValue: ({rowData}) => {
                // remove assignedTo key from rowData
                delete rowData.assignedTo;

                return rowData;
            },
            copyValue: ({rowData}) => {
                // find the value of 'assignedTo' key in rowData
                let value = rowData.assignedTo;
                // return the label of the choice that matches the value in choices
                return staffChoices.find(({value: v}) => v === value)?.label ?? '';

            },
            pasteValue: ({rowData, value}) => {
                // find the choice that matches the label in choices
                let choice = staffChoices.find(({label}) => label === value);

                // replace rowData.assignedTo with the value of the choice    
                rowData.assignedTo = choice?.value ?? '';

                return rowData;
            }
        },
        // { ...keyColumn('jobType', textColumn), title: '工作類別' },
        {component: JobTypeSelectComponent, title: '工作類別', disableKeys: true, key: 'typeID', 
            deleteValue: ({rowData}) => {
                // remove 'typeID' key from rowData
                delete rowData.typeID;

                return rowData;
            },
            copyValue: ({rowData}) => {
                // find the value of 'typeID' key in rowData
                let value = rowData.typeID;
                // return the label of the choice that matches the value in choices
                return jobTypeChoices.find(({value: v}) => v === value)?.label ?? '';

            },
            pasteValue: ({rowData, value}) => {
                // find the choice that matches the label in choices
                let choice = jobTypeChoices.find(({label}) => label === value);

                // replace rowData.typeID with the value of the choice    
                rowData.typeID = choice?.value ?? '';

                return rowData;
            }
        },
        {component: LocSelectComponent, title: '地點', disableKeys: true, key: 'locationID', 
            deleteValue: ({rowData}) => {
                // remove 'location' key from rowData
                delete rowData.locationID;

                return rowData;
            },
            copyValue: ({rowData}) => {
                // find the value of 'location' key in rowData
                let value = rowData.locationID;
                // return the label of the choice that matches the value in choices
                return locChoices.find(({value: v}) => v === value)?.label ?? '';

            },
            pasteValue: ({rowData, value}) => {
                // find the choice that matches the label in choices
                let choice = locChoices.find(({label}) => label === value);

                // replace rowData.location with the value of the choice    
                rowData.locationID = choice?.value ?? '';

                return rowData;
            }
        },
        { ...keyColumn('jobRemark', textColumn), title: '備註'},
        { ...keyColumn('jobPriority', intColumn), title: '工作優先', maxWidth: 70},
        { ...keyColumn('requiredInspection', checkboxColumn), title: '需要檢查', maxWidth: 80},
        { ...keyColumn('sendPhoneNotification', checkboxColumn), title: '電話通知', maxWidth: 80},
    ]
    
    // create states for filter
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const [selectedLoc, setSelectedLoc] = useState(null);
    const [selectedRequiredInspection, setSelectedRequiredInspection] = useState(null);
    

    // get today's date to set as default value for estimated date
    useEffect(() => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; // January is 0
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10 ) {
            mm = '0' + mm;
        }

        today = yyyy + '-' + mm + '-' + dd;
        setSelectedEstimatedDate(today);
    }, [])
        

    // apply filter value to data
    useEffect(() => {
        if (selectedJobType != null) {
            setData(data.map((row) => {
                row.typeID = selectedJobType.value;
                return row;
            }))
        }

        if (selectedStaff != null && selectedStaff.length > 0) {
            if (selectedStaff.length === 1) {
                setData(data.map((row) => {
                    row.assignedTo = selectedStaff[0].value;
                    return row;
                }))
            } else {
                // create corresponding rows in data for each selected staff
                let newData = [];
                let tempData = {};
                for (let i = 0; i < selectedStaff.length; i++) {
                    let staff = selectedStaff[i];

                    if (i < data.length) {
                        tempData = data[i];
                    } else {
                        tempData = {};
                    }

                    tempData.assignedTo = staff.value;
                    newData.push(tempData);
                }
                setData(newData);
            }
        }

        if (selectedLoc != null && selectedLoc.length > 1) {
            if (selectedLoc.length === 1) {
                setData(data.map((row) => {
                    row.locationID = selectedLoc[0].value;
                    return row;
                }))
            } else {
                // create corresponding rows in data for each selected location
                let newData = [];
                let tempData = {};
                for (let i = 0; i < selectedLoc.length; i++) {
                    let loc = selectedLoc[i];
                    if (i < data.length) {
                        tempData = data[i];
                    } else {
                        tempData = {};
                    }

                    tempData.locationID = loc.value;
                    newData.push(tempData);
                }
                setData(newData);
            }
        }

        if (selectedRequiredInspection != null) {
            setData(data.map((row) => {
                row.requiredInspection = selectedRequiredInspection;
                return row;
            }
            ))
        }


    }, [selectedStaff, selectedJobType, selectedLoc, selectedRequiredInspection])

    // delay rendering of DataSheetGrid until choices are loaded
    if (locChoices.length === 0 || jobTypeChoices.length === 0 || staffChoices.length === 0) {
        return null;
    }
   
    // converting datasheet grid component language to chinese
    const AddRows = createAddRowsComponent({
        button: '新增',
        unit: '行',
    });

    // changes default context menu location 
    const handleContextMenu = (e) => {
        e.preventDefault();

        // skip if it's not a right click
        if (e.button !== 2) {
            return;
        }

        // skip check if it is not a dsg-cell 
        if (!e.target.classList.contains('dsg-cell')) {
            return;
        } else {
            console.log("dsg-cell");
        }

        // get the left most and right most absolute position of the cell
        let left = e.target.getBoundingClientRect().left;
        
        let right = e.target.getBoundingClientRect().right;

        // calculate the middle point of the cell
        let mid = (left + right) / 2;

        setTimeout(() => {
            let filterContent = document.getElementById('filter-container');
            let contextMenu = document.getElementsByClassName('dsg-context-menu')[0];
            if (contextMenu) {
                mid -= filterContent.getBoundingClientRect().left;
                contextMenu.style.left = mid + 'px';
            }
        }, 100);

        // update the context menu (.dsg-context-menu) position (left) to the middle point of the cell
        // let contextMenu = document.getElementsByClassName('dsg-context-menu')[0];
        

    }


    const ContextMenu = createContextMenuComponent(item => {
        if (item.type === 'CUT') {
        return <>剪下</>
        }
    
        if (item.type === 'COPY') {
        return <>複製</>
        }
    
        if (item.type === 'PASTE') {
        return <>貼上</>
        }
    
        if (item.type === 'DELETE_ROW') {
        return <>刪除此行</>
        }
    
        if (item.type === 'DELETE_ROWS') {
        return (
            <>
            刪除第<b>{item.fromRow}</b>至<b>{item.toRow}行</b>
            </>
        )
        }
    
        if (item.type === 'INSERT_ROW_BELLOW') {
        return <>在下面新增一行</>
        }
    
        if (item.type === 'DUPLICATE_ROW') {
        return <>複製此行</>
        }
    
        if (item.type === 'DUPLICATE_ROWS') {
        return (
            <>
            複製第<b>{item.fromRow}</b>至<b>{item.toRow}行</b>
            </>
        )
        }
        
        // ... other menu items
      });

    // generate random id for each row
    const genId = () => Math.random().toString(36).substr(2, 9);

    return (
        <>  
            <p className='text-sm text-gray-600 mb-1'>
                選擇其中一個選項以會自動把列表下的所有工作設定為選擇的選項
            </p>
            <div id="filter-container" className="md:grid md:grid-cols-6 gap-2.5 mb-3 flex flex-wrap">
                <MultiSelect
                    options={locChoices}
                    value={selectedLoc || []}
                    onChange={setSelectedLoc}
                    labelledBy={"Select"}
                    className='text-sm'
                    overrideStrings={
                        {
                            "selectSomeItems": "選擇地點",
                            "allItemsAreSelected": "所有地點已選擇",
                            "selectAll": "選擇所有地點",
                            "search": "搜尋",
                        }
                    }
                />    
                <Select
                    options={jobTypeChoices}
                    placeholder="選擇工作類別"
                    value={selectedJobType}
                    onChange={setSelectedJobType}
                    className='text-sm text-nowrap'
                />  
                <MultiSelect
                    options={staffChoices}
                    value={selectedStaff || []}
                    onChange={setSelectedStaff}
                    labelledBy={"Select"}
                    className='text-sm h-[36px]'
                    placeholder="選擇施工人員"
                    overrideStrings={
                        {
                            "selectSomeItems": "選擇施工人員",
                            "allItemsAreSelected": "所有施工人員已選擇",
                            "selectAll": "選擇所有施工人員",
                            "search": "搜尋",
                        }
                    }
                />                    
                <div className="flex items-center justify-center col-span-1">
                    <label 
                        htmlFor="required-inspection"
                        className="flex items-center cursor-pointer text-sm mr-2"
                    >需要檢查 (全選)</label>
                    <input 
                        type="checkbox"
                        id="required-inspection"
                        name="required-inspection"
                        checked={selectedRequiredInspection}
                        className='w-4 h-4'
                        onChange={(e) => {
                            setSelectedRequiredInspection(e.target.checked);
                        }}
                    />
                </div>
                <div className="relative">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                        預計工作日期
                    </label>
                    <input
                        type="date"
                        name="estimatedDate"
                        id="estimatedDate"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={selectedEstimatedDate}
                        onChange={(e) => {
                            setSelectedEstimatedDate(e.target.value);
                        }}
                    />
                </div>
                <div className="flex items-center justify-center col-span-1">
                    <JobReminderDialog staffChoices={staffChoices} statusNotificationList={jobReminderList} setStatusNotificationList={setJobReminderList} />
                </div>
                

            </div>
            <hr></hr>
            {/* job assignment summary */}
            <JobAssignmentSummary 
                data={data} 
                staffChoices={staffChoices} 
                setData={setData} 
                setSelectedTemplate={setSelectedTemplate}
            />
            <div onMouseDown={handleContextMenu}>
                <DataSheetGrid 
                    createRow={() => ({ id: genId() })}
                    duplicateRow={({ rowData }) => ({ ...rowData, id: genId() })}
                    value={data}
                    onChange={(newValue, operations) => {
                        for (const operation of operations) {

                            // // Handle create
                            // if (operation.type === 'CREATE') {
                            //   newValue
                            //     .slice(operation.fromRowIndex, operation.toRowIndex)
                            //     .forEach(({ id }) => createdRowIds.add(id))
                            // }
                      
                            // Handle update
                            if (operation.type === 'UPDATE') {
                                // handleRowUpdate(operation.toRowIndex);
                                setEditedRow(operation.toRowIndex);
                              }
                            // Handle delete
                        }

                        setData(newValue);
                    }}
                    columns={columns}
                    autoAddRow={true}
                    rowHeight={33}
                    height={800}
                    addRowsComponent={false}
                    contextMenuComponent={ContextMenu}
                    
                />
            </div>
            {/* implement an addrow component */}
            <AddRowsComp setData={setData} />
            
        </>
    )
}

// adding rows to DataSheetGrid
function AddRowsComp({setData}) {

    return (
        <div
            className='flex items-center gap-x-2 mt-3 mb-3'
        >
            <input 
                type="number"
                id="add-row-input"
                className="w-30 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-sm"
                placeholder="新增行數"
            />
            <button
                type="button"
                className="rounded-md bg-indigo-600 text-white px-3 py-2 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={() => {
                    let input = document.getElementById('add-row-input');
                    let value = input.value;
                    if (value === '') {
                        // add one row if no value is entered
                        setData((prevData) => [...prevData, {id: Math.random().toString(36).substr(2, 9)}]);
                    }
                    let rows = parseInt(value);
                    let newData = [];
                    for (let i = 0; i < rows; i++) {
                        newData.push({id: Math.random().toString(36).substr(2, 9)});
                    }
                    setData((prevData) => [...prevData, ...newData]);
                    input.value = '';
                }}
            >+ 新增</button>
        </div>
    )
}

// Display job assignment summary 
function JobAssignmentSummary({data, staffChoices, setData, setSelectedTemplate}) {
    const [jobSummary, setJobSummary] = useState([]);

    // summarize job assignments by assignedTo
    useEffect(() => {
        // return if data is not greater than 0
        if (data.length < 1) {
            return;
        }

        let summary = {};
        data.forEach((row) => {
            if (row.hasOwnProperty('assignedTo')) {
                // skip if assignedTo is an empty string
                if (row.assignedTo === '') {
                    return;
                }
                // if assignedTo is already in summary, increment the count
                if (summary.hasOwnProperty(row.assignedTo)) {
                    summary[row.assignedTo] += 1;
                } else {
                    summary[row.assignedTo] = 1;
                }
            }
        })

        // convert key to label for each staff
        let newSummary = {};
        Object.keys(summary).forEach((key) => {
            let staff = staffChoices.find((staff) => staff.value === key);
            newSummary[staff.label] = summary[key];
        })

        // sort the summary by staff name
        let sortedSummary = Object.keys(newSummary).sort().reduce(
            (obj, key) => {
                obj[key] = newSummary[key];
                return obj;
            },
            {}
        );

        setJobSummary(sortedSummary);
    }, [data])

    // query for job templates 
    const [jobTemplateChoices, setJobTemplateChoices] = useState([]);
    useEffect(() => {
        let jobTemplateUrl = GlobalVar.BACKEND_DOMAIN + '/api/v1/job/manage-templates?organizationID=' + sessionStorage.getItem('organizationId');
        fetch(jobTemplateUrl,{
            headers: {
              'Authorization': sessionStorage.getItem('idToken')
            }
          })
            .then(response => response.json())
            .then(rawData => {
                // return error if jobTemplates key doesn't exist all the length of value is zero
                if (!rawData.hasOwnProperty('templates') || rawData['templates'].length === 0) {
                    return new Error('No job templates found');
                }

                let data = rawData['templates'];
                
                // set up choices
                let choices = data.map((template) => {
                    return {value: template._id, label: template.templateName}
                });

                setJobTemplateChoices(choices);
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    const queryJobTemplate = (jobTemplateId) => {
        // create fetch request with a jobTemplateId as templateId
        let url = GlobalVar.BACKEND_DOMAIN + '/api/v1/job/manage-templates?templateId=' + jobTemplateId['value'];
        fetch(url,{
            headers: {
              'Authorization': sessionStorage.getItem('idToken')
            }
          })
            .then(response => response.json())
            .then(rawData => {
                // return error if jobTemplates key doesn't exist all the length of value is zero
                if (!rawData.hasOwnProperty('template')) {
                    return new Error('No job template found');
                }

                let templateDetail = rawData['template'];

                // set the selected template
                setSelectedTemplate(templateDetail);
                
                // populate the data to the DataSheetGrid
                setData(templateDetail['jobs']);
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }


    return (
        <div
            className="mt-3"
        >   
        <div
            className='flex justify-between items-center'
        >
            <h1
                className="text-lg font-semibold text-gray-900 mb-2"
            >工作分配概覽</h1>
            <Select
                options={jobTemplateChoices}
                placeholder="選擇工作模板"
                onChange={(value) => {
                    queryJobTemplate(value);
                }}
                className='text-sm w-60'
            />
        </div>
            <div
                className='flex flex-wrap gap-x-2 gap-y-2 mb-2'
            >
                {/* iterate through each key  */}
                {Object.keys(jobSummary).map((key) => {
                    return (
                        <div 
                            key={key} 
                            className="flex items-center gap-x-1 bg-indigo-200 rounded px-2 py-1"
                        >
                            <span
                                className='text-sm font-semibold text-indigo-900'
                            >{key} : </span>
                            <span
                                className='text-sm text-gray-600'
                            >{jobSummary[key]}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

// select component for DataSheetGrid
// function SelectComponent(CellPro) {

// }

// dialog for adding job reminder to jobs being created
function JobReminderDialog({staffChoices, statusNotificationList, setStatusNotificationList}) {
    // const [statusNotificationList, setStatusNotificationList] = useState([{"name": "", "targets": []}]);

    // handle input change for status inputs
    const handleStatusInputChange = (index, e) => {
        console.log(e.target.value);
        const values = [...statusNotificationList];
        values[index][e.target.name] = e.target.value;
        setStatusNotificationList(values);
    };


    // handle input change when users interact with multiselect
    const handleMultiUserSelect = (ind, selectedOptions) => {
        const values = [...statusNotificationList];
        values[ind]['targets'] = selectedOptions;
        setStatusNotificationList(values);
    }

    // handle adding another set of reminder
    const handleAddAnotherReminder = (e) => {
    e.preventDefault();
    setStatusNotificationList([...statusNotificationList, {"name": "", "targets": []}]);
    };

    // handle removing last set of reminder
    const handleRemoveLastReminder = (e) => {
        e.preventDefault();
        setStatusNotificationList(statusNotificationList.slice(0, -1));
        
    };

    const popBtnRef = useRef();

    // handle job reminder button text
    const [jobReminderBtnText, setJobReminderBtnText] = useState('狀態通知');
    useEffect(() => {
        // if there are at least one statusNotificationList with filled name and targets, change the text to '狀態通知 (已設定<數量>)'
        if (statusNotificationList.length > 0) {
            let filledStatusNotificationList = statusNotificationList.filter((status) => {
                return status.name !== "" && status.targets.length > 0;
            })
            if (filledStatusNotificationList.length > 0) {
                setJobReminderBtnText('狀態通知 (已設定' + filledStatusNotificationList.length + ')');
            } else {
                setJobReminderBtnText('狀態通知');
            }
        }
    }, [statusNotificationList])


    return (
        <Popover className="relative" id="job-reminder-dialog">
            <Popover.Button ref={popBtnRef} className="inline-flex items-center gap-x-1 text-sm leading-6 text-gray-900 rounded ring-1 ring-inset ring-gray-300 hover:bg-gray-50 duration-300 px-3 py-1">
                <span>{jobReminderBtnText}</span>
                {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute right-1/2  z-10 mt-5 flex w-screen max-w-max px-4 ">
                <div className="w-screen max-w-md flex-auto overflow-visible rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                        {/* content  */}
                        <div className="flex flex-col gap-y-2">
                            {/* status name */}
                            {statusNotificationList.map((status, index) => {
                                return (
                                    <div className="flex flex-col gap-y-1" key={index}>
                                        <label htmlFor="status-name" className="text-gray-700">狀態名稱</label>
                                        <select
                                            name="name"
                                            id="status-name"
                                            className="text-sm rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            value={status.name}
                                            onChange={(e) => handleStatusInputChange(index, e)}
                                        >
                                            <option value="">選擇狀態</option>
                                            {Object.keys(jobStatus).map((key) => {
                                                return (
                                                    <option value={jobStatus[key]} key={key}>{key}</option>
                                                )
                                            })}
                                        </select>
                                        <label htmlFor="status-name" className="text-gray-700">通知對象</label>
                                        <MultiSelect
                                            options={staffChoices}
                                            value={status.targets}
                                            onChange={(selectedOptions) => handleMultiUserSelect(index, selectedOptions)}
                                            labelledBy={"Select"}
                                            className='text-sm'
                                            overrideStrings={
                                                {
                                                    "selectSomeItems": "選擇通知對象",
                                                    "allItemsAreSelected": "所有通知對象已選擇",
                                                    "selectAll": "選擇所有通知對象",
                                                    "search": "搜尋",
                                                }
                                            }
                                        /> 
                                    </div>
                                )
                            })}
                            {/* add and remove buttons */}
                            <div className="flex gap-x-2">
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={handleAddAnotherReminder}
                                >
                                    新增
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={handleRemoveLastReminder}
                                >
                                    移除
                                </button>
                            </div>
                        </div>
                        {/* footer buttons for cancel and save */}
                        <div className="flex justify-end gap-x-2">
                            <button
                                type="button"
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                onClick={() => {
                                    setStatusNotificationList([{"name": "", "targets": []}]);
                                    // close popover
                                    popBtnRef.current?.click();
                                }}
                            >
                                取消
                            </button>
                            
                            <button
                                type="button"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={() => {
                                    // close popover
                                    popBtnRef.current?.click();
                                }}
                            >
                                儲存
                            </button>
                        </div>
                    </div>
                </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

