import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { ExclamationTriangleIcon, PlusIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../Config';
import { useNavigate } from 'react-router-dom';

export default function AddNewProject({
    open,
    setOpen
}) {
    const [projectName, setProjectName] = useState('');
    const [date, setDate] = useState('');
    const [responsiblePerson, setResponsiblePerson] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        // preset date to today
        setDate(new Date().toISOString().split('T')[0])
    }, [])

    // handle add new project
    const [isLoading, setIsLoading] = useState(false);
    const handleAddNewProject = () => {

        let body = {
            projectTitle: projectName,
            projectStartDate: date,
            assignedTo: responsiblePerson.id,
            organizationID: localStorage.getItem('organizationId')
        }

        // make sure all fields are filled
        if (!body.projectTitle || !body.projectStartDate || !body.assignedTo) {
            alert('請填寫所有欄位')
            return
        }

        // prompt user to confirm
        if (!window.confirm('確定要新增項目嗎？')) {
            return
        }


        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project`, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                alert('新增失敗')
            }
        })
        .then(data => {
            alert('新增成功, 現在為你跳轉到項目管理頁面')
            navigate(`/project-management/${data.projectID}`)
        })
        .catch(error => console.error('Error adding new project:', error));
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
                transition
                className="relative transform rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-lg">
                    <div className="sm:flex sm:items-start mb-4">
                        <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                        <PlusIcon aria-hidden="true" className="size-6 text-indigo-600" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            新增項目
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                            請輸入項目名稱、日期及負責人。
                            </p>
                        </div>
                        </div>
                    </div>
                    <div>
                        <FormBody 
                            projectName={projectName}
                            setProjectName={setProjectName}
                            date={date}
                            setDate={setDate}
                            responsiblePerson={responsiblePerson}
                            setResponsiblePerson={setResponsiblePerson}
                        />
                    </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-b-lg">
                <button
                    type="button"
                    onClick={handleAddNewProject}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                    {isLoading ? '新增中...' : '新增'}
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                    取消
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
    )
}

// include all the inputs and labels
function FormBody({
    projectName,
    setProjectName,
    date,
    setDate,
    responsiblePerson,
    setResponsiblePerson
}) {
    return (
        <div
            className="space-y-4"
        >
            <div>
            <label htmlFor="projectName" className="block text-sm/6 font-medium text-gray-900">
                項目名稱
            </label>
            <div className="mt-2">
                <input
                id="projectName"
                name="projectName"
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                />
                </div>
            </div>
            <div>
                <label htmlFor="date" className="block text-sm/6 font-medium text-gray-900">
                    日期
            </label>
            <div className="mt-2">
                <input
                    id="date"
                    name="date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />
                </div>
            </div>
            <div>
                <UserSelection 
                    selectedPerson={responsiblePerson}
                    setSelectedPerson={setResponsiblePerson}
                />
            </div>
        </div>
    )
}

function UserSelection({
    selectedPerson,
    setSelectedPerson
}) {
    const [query, setQuery] = useState('')
    const [people, setPeople] = useState([])

    const filteredPeople =
        query === ''
        ? people
        : people.filter((person) => {
            return person.name.toLowerCase().includes(query.toLowerCase())
            })

    // query the people from the backend
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users?organizationID=' + localStorage.getItem('organizationId');
        fetch(url)
            .then(response => response.json())
            .then(data => {
                const formattedUsers = data.users.map(user => ({
                    id: user._id,
                    name: user.displayName
                }));
                setPeople(formattedUsers)

                // preset the logged in user
                setSelectedPerson(formattedUsers.find(user => user.id === localStorage.getItem('userid')))
            })
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    return (
        <Combobox
        as="div"
        value={selectedPerson}
        onChange={(person) => {
            setQuery('')
            setSelectedPerson(person)
        }}
        >
        <Label className="block text-sm/6 font-medium text-gray-900">負責人</Label>
        <div className="relative mt-2">
            <ComboboxInput
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery('')}
            displayValue={(person) => person?.name}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            {filteredPeople.length > 0 && (
            <ComboboxOptions className="absolute z-[100] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredPeople.map((person) => (
                <ComboboxOption
                    key={person.id}
                    value={person}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                >
                    <span className="block truncate group-data-[selected]:font-semibold">{person.name}</span>

                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                    <CheckIcon className="size-5" aria-hidden="true" />
                    </span>
                </ComboboxOption>
                ))}
            </ComboboxOptions>
            )}
        </div>
        </Combobox>
    )
}
