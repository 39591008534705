import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { 
    EllipsisVerticalIcon, 
    InformationCircleIcon
} from '@heroicons/react/20/solid'
import { useState } from 'react'
export default function InfoTag ({
    content,
    type,
    alignLeft
}) {
  const [recommendation, setRecommendation] = useState(false);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
        <MainIcon icon={type} />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className={`absolute ${alignLeft ? "left-0" : "right-0"} z-50 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
      >
        <div className="py-1">
          <MenuItem>
            <span
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              {content}
            </span>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  )
}

function MainIcon({ icon }) {
    if (icon == "info") {
        return (
            <InformationCircleIcon aria-hidden="true" className="h-5 w-5" />
        )
    } else {
        return (
            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
        )
    }
    
}
