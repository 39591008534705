import { Fragment, useState, useCallback } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { 
    EllipsisVerticalIcon,
    PlusIcon,
    LinkIcon,
    QuestionMarkCircleIcon,
 } from '@heroicons/react/20/solid'

import { GridCellKind, DataEditor } from "@glideapps/glide-data-grid";

 import "@glideapps/glide-data-grid/dist/index.css";



 const data = [
  {
    "name": "Hines Fowler",
    "company": "BUZZNESS",
    "email": "hinesfowler@buzzness.com",
    "phone": "+1 (869) 405-3127"
  },
]
 const columns = [
  {
      title: "Name",
      id: "name"
  },
  {
      title: "Company",
      id: "company"
  },
  {
      title: "Email",
      id: "email"
  },
  {
      title: "Phone",
      id: "phone"
  }
]

export default function DeleteMe() {

  const getCellContent = useCallback((cell) => {
    const [col, row] = cell;
    const dataRow = data[row];
    const d = dataRow[columns[col].id]; // Accessing data using column id
    return {
          kind: GridCellKind.Text, // This can be changed to other types if needed
          allowOverlay: true, // Allow overlay for editing
          displayData: d,
          data: d,
      };
  }, []);

const handleCellEdit = useCallback((cell, value) => {
    const [col, row] = cell;
    const columnId = columns[col].id;
    data[row][columnId] = value; // Update the data with the new value
}, []);

return (
    <DataEditor 
      getCellContent={getCellContent} 
      columns={columns} // Pass columns to DataEditor
      rows={data.length} 
      onCellEdit={handleCellEdit} // Handle cell edits
    />
);
}