import {useState, useEffect} from 'react';
import GlobalVars from '../../../../Config';
import {
    ClockIcon,
    CheckIcon,
} from '@heroicons/react/20/solid';

export default function ModuleSection({
    jobTypes,
    setModuleOpen,
    setModuleName,
    jobID,
}) {
    const [modules, setModules] = useState([]);
    const [moduleStatues, setModuleStatuses] = useState([]);
    useEffect(() => {
        // return if jobTypes is undefined
        if (!jobTypes) return;

        // fetch selected modules for the job type
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/get-all-related-modules";
        let data = {
            typeIDs: jobTypes
        }
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setModules(data);
            data.forEach(module => {
                fetchModuleStatus(module.statusEndpoint)
            })
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }, [jobTypes]);

    const fetchModuleStatus = (endpoint) => {
        fetch(GlobalVars.BACKEND_DOMAIN + endpoint + "?jobID=" + jobID)
        .then(response => response.json())
        .then(statusData => {
            setModuleStatuses(prevStatuses => ({
                ...prevStatuses,
                [endpoint]: statusData.message
            }));
        })
        .catch((error) => {
            console.error('Error fetching module status:', error);
        });
    };

    return (
        <>
            {modules.length > 0 &&
                <div className="mt-6">
                    <h3 className="mt-6 mb-2 text-base font-medium leading-6 text-gray-900">子模組</h3>
                    <p className="text-sm text-gray-500 mb-2">以下選項是基於此工作的工作類別</p>
                    <dl className="grid grid-cols-1 sm:grid-cols-6 border-t border-gray-100 p-2">
                        {modules.map((module, index) => {
                            return (
                                <div 
                                    className="border-2 border-gray-600 px-3 py-3 sm:col-span-1 rounded-lg cursor-pointer hover:bg-gray-100 duration-300 flex justify-between"
                                    onClick={() => {
                                        setModuleOpen(true);
                                        setModuleName(module['langVar']['tc']);
                                    }}
                                >
                                    <div>
                                        <dt className="text-sm font-medium leading-6 text-gray-800">{module['langVar']['tc']}</dt>
                                        {module['desc'] &&
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{module['desc']}</dd>
                                        }
                                    </div>
                                    {moduleStatues[module.statusEndpoint] === "completed" ?
                                        <CheckIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                        :
                                        <ClockIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    }
                                </div>
                            )
                        })}
                    </dl>
                </div>
            }
        </>
    )
}