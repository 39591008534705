import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/outline'
export default function DashboardFilter({
    targetMonth,
    setTargetMonth,
    targetYear,
    setTargetYear,
    yearList,
    filter,
    setFilter
}) {
    return (
        <div
            className="flex flex-row gap-4 flex-wrap"
        >   
            <div
                className="w-full sm:w-[30%]"
            >
                <TimeFilter 
                    targetMonth={targetMonth}
                    setTargetMonth={setTargetMonth}
                    targetYear={targetYear}
                    setTargetYear={setTargetYear}
                    yearList={yearList}
                />
            </div>
            <div
                className="w-full sm:w-[30%]"
            >
                <JobTypeFilter 
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    )
}

// time filter with month and year
function TimeFilter({
    targetMonth,
    setTargetMonth,
    targetYear,
    setTargetYear,
    yearList
}) {
    return (
        <div
            className="w-full"
        >
            <label className="block text-sm/6 font-medium text-gray-900">選擇月份</label>
            <div
                className="flex flex-row py-1.5"
            >
                <select
                id="month"
                name="month"
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                defaultValue={targetMonth}
                onChange={(e) => setTargetMonth(e.target.value)}
                >
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                </select>
                <select 
                id="year"
                name="year"
                className="ml-3 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={targetYear}
                onChange={(e) => setTargetYear(e.target.value)}
                >
                {yearList.map((year) => (
                    <option value={year}>{year}</option>
                ))}
                </select>
            </div>
        </div>
    )
}

// job type filter
function JobTypeFilter({
    filter,
    setFilter
}) {
    const [query, setQuery] = useState('')
    const [jobTypeList, setJobTypeList] = useState([]);
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?organizationID=" + sessionStorage.getItem("organizationId");
        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                // process data and add default "全選" option
                let jobTypeList = [{
                    id: 'all',
                    name: '全選'
                }, ...data['jobTypes'].map((jobType) => {
                    return {
                        id: jobType._id,
                        name: jobType.langVar && jobType.langVar.tc ? `${jobType.langVar.tc} (${jobType.name})` : jobType.name
                    }
                })];
                
                setJobTypeList(jobTypeList)

                // select the default "全選" option
                setFilter({
                    ...filter,
                    job_type: jobTypeList[0]
                })
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const filterList = 
        query === '' ? jobTypeList : jobTypeList.filter((jobType) => jobType.name.toLowerCase().includes(query.toLowerCase()));

    return (
        <Combobox
            as="div"
            value={filter?.job_type || {}}
            onChange={(jobType) => {
                setQuery('')
                setFilter({
                    ...filter,
                    job_type: jobType
                })
            }}
        >
            <Label className="block text-sm/6 font-medium text-gray-900">工作類型</Label>
            <div className="relative mt-2">
            <ComboboxInput
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                onChange={(event) => setQuery(event.target.value)}
                onBlur={() => setQuery('')}
                displayValue={(jobType) => jobType?.name}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
    
            {filterList.length > 0 && (
                <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filterList.map((jobType) => (
                    <ComboboxOption
                    key={jobType.id}
                    value={jobType}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                    >
                    <span className="block truncate group-data-[selected]:font-semibold">{jobType.name}</span>
    
                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                        <CheckIcon className="size-5" aria-hidden="true" />
                    </span>
                    </ComboboxOption>
                ))}
                </ComboboxOptions>
            )}
            </div>
        </Combobox>
    )
}