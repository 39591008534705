import Tiptap from './Components/Tiptap';
import { useState, useEffect } from 'react';

export default function Note() {
    // load initial content from the database
    const [initialContent, setInitialContent] = useState('<p>Hello World!</p>');

    return (
        <div className="card">
          <Tiptap className="tiptap" initialContent={initialContent} />
        </div>
    );
}
