import { useEffect, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import MinibarPosting from '../MinibarPosting'
import RoomStatus from '../RoomStatus'
import Checklist from '../Checklist'

export default function ModuleDrawer({
    open,
    setOpen,
    moduleName,
    setOpenModuleNotification,
    jobID,
}) {

    const [saveTriggered, setSaveTriggered] = useState(false)

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                >
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                    <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">{moduleName}</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                            <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-x-auto">
                        <Modulebody 
                            moduleName={moduleName}
                            saveTriggered={saveTriggered}
                            setSaveTriggered={setSaveTriggered}
                            setOpenModuleNotification={setOpenModuleNotification}
                            jobID={jobID}
                            
                        />
                    </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    >
                        取消
                    </button>
                    <button
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={() => {
                            // prompt users confirmation
                            if (!window.confirm('確定要儲存嗎?')) {
                                return
                            }
                            setSaveTriggered(true)
                            setOpen(false)
                        }}
                    >
                        儲存
                    </button>
                    </div>
                </div>
                </DialogPanel>
            </div>
            </div>
        </div>
        </Dialog>
    )
}

function Modulebody ({moduleName, saveTriggered, setSaveTriggered, setOpenModuleNotification, jobID}) {
    if (moduleName == "報Bar") {
        return (
            <MinibarPosting 
                saveTriggered={saveTriggered}
                setSaveTriggered={setSaveTriggered}
                setOpenModuleNotification={setOpenModuleNotification}
                jobID={jobID}
            />
        )
    } else if (moduleName == "更改房間狀態") {
        
        return (
            <RoomStatus 
                saveTriggered={saveTriggered}
                setSaveTriggered={setSaveTriggered}
                setOpenModuleNotification={setOpenModuleNotification}
                jobID={jobID}
            />
        )

    } else if (moduleName == "清單") {
        return (
            <Checklist
                saveTriggered={saveTriggered}
                setSaveTriggered={setSaveTriggered}
                setOpenModuleNotification={setOpenModuleNotification}
                jobID={jobID}
            />
        )
    } else {
        return (
            <div className="flex items-center justify-center h-full">
                <p className="text-red-500 text-lg font-semibold">
                    顯示子模組內容時出現錯誤
                </p>
            </div>
        )
    }

    
}
