import { useState } from 'react';
import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';

// components
import ProjectList from './Components/ProjectList';

export default function ProjectManagement() {
    // set page title
    document.title = "項目管理";

    return (
        <ApplicationShell>
            <div className={`flex flex-col h-[85vh]`}>
                  {/* <div className="flex-shrink-0 md:flex md:items-center md:justify-between py-4">
                      <div className="min-w-0 flex-1">
                          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">項目管理</h2>
                      </div>
                  </div> */}
                  <ProjectList />
            </div>
        </ApplicationShell>
    )
}


