import { useState, useEffect } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { UserIcon } from '@heroicons/react/20/solid'
import GlobalVars from '../../../../Config';
import { useParams } from 'react-router-dom';

// components
import MembersSelect from '../MemberSelect'

export default function NewBoardItems({
    open,
    setOpen,
    boardID
}) {
    const {id} = useParams();

    const [outputs, setOutputs] = useState({
        boardItemName: '',
        priority: 'low',
        deadline: new Date().toISOString().split('T')[0],
        remarks: '',
        attachments: [],
        boardID: boardID,
        assignedTo: []
    });

    useEffect(() => {
        setOutputs({
            ...outputs,
            boardID: boardID
        })
    }, [boardID])


    const [selectedAttachments, setSelectedAttachments] = useState([])
    // When files are selected
    const handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files);
        setSelectedAttachments(filesArray);
        setOutputs(prev => ({
            ...prev,
            attachments: filesArray
        }));
    };

    const handleDeleteFile = (fileToDelete) => {
        // Filter by filename instead of direct object comparison
        setSelectedAttachments(prevAttachments => 
            prevAttachments.filter(file => file.name !== fileToDelete.name)
        );
        
        setOutputs(prevOutputs => ({
            ...prevOutputs,
            attachments: prevOutputs.attachments.filter(file => file.name !== fileToDelete.name)
        }));
    };

    const handleFileUpload = async (data, newBoardItemResp) => {
        // Create array of promises for each file upload
        const uploadPromises = data.urls.map((url, index) => {
            // Step 1: Get signed URL
            return fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: selectedAttachments[index]
            })
            .then(response => {
                if (!response.ok) throw new Error('Failed to upload file');
                return response.json();
            })
            .then(data => {
                console.log('file uploaded', data)
            })
            .then(response => {
                if (!response.ok) throw new Error('Failed to update backend records');
                return response.json();
            });
        });

        // Wait for all uploads to complete
        try {
            await Promise.all(uploadPromises);
            console.log('All files uploaded successfully');
            // update backend records
            fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/files`, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    projectID: id,
                    fileNameList: outputs.attachments.map(file => file.name),
                    fileTypeList: outputs.attachments.map(file => file.type),
                    assignedTo: localStorage.getItem('userid'),
                    itemID: newBoardItemResp['itemID']
                })
            })
            .then(response => {
                if (!response.ok) throw new Error('Failed to update backend records')
                return response.json()
            })
            .then(data => {
                console.log('backend records updated', data)
                alert('工作項目已成功建立')
                setOpen(false)
            })
            .catch(error => console.error('Error updating backend records:', error));
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    }

    const handleSubmit = () => {
        let body = outputs;

        // ensure all fields are filled
        if (!body.boardItemName) {
            alert('請輸入工作名稱')
            return
        }

        // convert boardItemName to itemTitle
        body.itemTitle = body.boardItemName;
        delete body.boardItemName;


        // convert attachments to array of file names
        body.attachments = body.attachments.map(file => file.name);

        // send request
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
            method: 'POST',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log('response', response.json());
                throw new Error('Failed to create board item')
            }
        })
        .then(newBoardItemResp => {
            setOpen(false)

            // clear outputs
            setOutputs({
                boardItemName: '',
                priority: 'low',
                deadline: new Date().toISOString().split('T')[0],
                remarks: '',
                attachments: [],
            })

            // get signed urls for attachments
            fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url`, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileNameList: outputs.attachments.map(file => file.name),
                    fileTypeList: outputs.attachments.map(file => file.type),
                    organizationID: localStorage.getItem('organizationId'),
                    projectID: id
                })
            })
            .then(response => {
                if (!response.ok) throw new Error('Failed to get signed URLs')
                return response.json()
            })
            .then(data => {
                handleFileUpload(data, newBoardItemResp)
            })
            .catch(error => console.error('Error getting signed URLs:', error));
        })
        .catch(error => console.error('Error creating board item:', error));
    }

    const handleDeleteMember = (member) => {
        setOutputs(prev => ({
            ...prev,
            assignedTo: prev.assignedTo.filter(m => m._id !== member._id)
        }))
    }
  
    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                >
                <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                        <DialogTitle className="text-base font-semibold text-white">新增工作</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                            <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        </div>
                        <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                            在此新增工作項目，包含工作名稱、優先級別、截止日期、備註及附件。您也可以指派團隊成員負責此工作。
                        </p>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5 pt-6">
                            <div>
                            <label htmlFor="project-name" className="block text-sm/6 font-medium text-gray-900">
                                工作名稱
                            </label>
                            <div className="mt-2">
                                <input
                                id="board-item-name"
                                name="board-item-name"
                                type="text"
                                value={outputs.boardItemName}
                                onChange={(e) => setOutputs({...outputs, boardItemName: e.target.value})}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                            </div>
                            </div>
                            <div>
                                <label htmlFor="priority" className="block text-sm/6 font-medium text-gray-900 mb-2">
                                    優先級別
                                </label>
                                <PrioritySelect 
                                    outputs={outputs}
                                    setOutputs={setOutputs}
                                />
                            </div>
                            
                            <div>
                            <h3 className="text-sm/6 font-medium text-gray-900">團隊成員</h3>
                            <div className="mt-2">
                                <div className="flex space-x-2 flex-wrap">
                                {outputs.assignedTo && outputs.assignedTo.map((person) => (
                                    <button
                                    key={person?._id}
                                    className="relative rounded-full hover:opacity-75 group"
                                    >
                                        <div className="flex items-center">
                                            <UserIcon
                                                className="inline-block size-8 rounded-full group-hover:opacity-30 transition-opacity duration-200"
                                            />
                                            <span className="text-sm text-gray-500 whitespace-nowrap">{person?.displayName}</span>
                                        </div>
                                        {/* Overlay Icon */}
                                        <button className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-white/70"
                                            onClick={() => handleDeleteMember(person)}
                                        >
                                            <XMarkIcon className="h-4 w-4 text-gray-800" />
                                        </button>
                                    </button>
                                ))}
                                <MembersSelect 
                                    outputs={outputs}
                                    setOutputs={setOutputs}
                                />
                                </div>
                            </div>
                            </div>
                            <div>
                                <label htmlFor="deadline" className="block text-sm/6 font-medium text-gray-900">
                                    目標完成日期
                                </label>
                                <div className="mt-2">
                                    <input
                                    id="deadline"
                                    name="deadline"
                                    type="date"
                                    value={outputs.deadline}
                                    onChange={(e) => setOutputs({...outputs, deadline: e.target.value})}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
                                    備註
                                </label>
                                <div className="mt-2">
                                    <textarea
                                    id="remarks"
                                    name="remarks"
                                    rows={4}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    defaultValue={''}
                                    value={outputs.remarks}
                                    onChange={(e) => setOutputs({...outputs, remarks: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div>
                                <p className="text-sm/6 font-medium text-gray-900">附件</p>
                                <div className="mt-2 ">
                                    <label className="w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" htmlFor="attachments">選擇附件</label>
                                    <input 
                                        id="attachments"
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        className="hidden w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {/* show attachments */}
                                    {selectedAttachments?.length > 0 && selectedAttachments.map((attachment) => (
                                        <div key={attachment.name} className="flex items-center mt-2">
                                            <span className="text-sm flex-1 truncate text-gray-500">{attachment.name}</span>
                                            <XMarkIcon className="size-4 ml-2 cursor-pointer hover:text-red-500" onClick={() => handleDeleteFile(attachment)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="pb-6 pt-4">
                            <div className="flex text-sm">
                            <a
                                href="#"
                                className="group inline-flex items-center font-medium text-indigo-600 hover:text-indigo-900"
                            >
                                <LinkIcon
                                aria-hidden="true"
                                className="size-5 text-indigo-500 group-hover:text-indigo-900"
                                />
                                <span className="ml-2">Copy link</span>
                            </a>
                            </div>
                            <div className="mt-4 flex text-sm">
                            <a href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                                <QuestionMarkCircleIcon
                                aria-hidden="true"
                                className="size-5 text-gray-400 group-hover:text-gray-500"
                                />
                                <span className="ml-2">Learn more about sharing</span>
                            </a>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    </div>
                    <div className="flex shrink-0 justify-end px-4 py-4">
                    <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        取消
                    </button>
                    <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                    >
                        儲存
                    </button>
                    </div>
                </div>
                </DialogPanel>
            </div>
            </div>
        </div>
        </Dialog>
    )
}

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}
  
function PrioritySelect({
    outputs,
    setOutputs
}) {
    const [tabs, setTabs] = useState([
        { name: '低', value: 'low', current: true },
        { name: '中', value: 'medium', current: false },
        { name: '高', value: 'high', current: false },
    ])

    const handleChange = (value) => {
        setOutputs({...outputs, priority: value})
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.value === value
        })))
    }

    useEffect(() => {
        // update tabs when outputs change
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.value === outputs.priority
        })))
    }, [outputs])


    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 cursor-pointer"
            onChange={handleChange}
            value={outputs.priority}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav aria-label="Tabs" className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
            {tabs.map((tab, tabIdx) => (
              <button
                key={tab.name}
                onClick={() => handleChange(tab.value)}
                className={classNames(
                  tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
                )}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? 'bg-indigo-500' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5',
                  )}
                />
              </button>
            ))}
          </nav>
        </div>
      </div>
    )
  }
  


