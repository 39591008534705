import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition, DialogTitle, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon, HandThumbUpIcon, UserIcon, InformationCircleIcon, BriefcaseIcon } from '@heroicons/react/20/solid'
import './NotificationList.css';
import GlobalVars from '../../Config';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function NotificationFeed({timeline, loader}) {
    // handle users clicking on a notification
    const handleNotificationClick = (url, eventID) => {

        let fetchUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification/read-status"
        fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": sessionStorage.getItem("idToken")
            },
            body: JSON.stringify({
              notificationID: eventID
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.log(error);
        });
        
        // redirect user to url
        if (url == undefined) {
          url = "#";
        }
        window.location.href = url;

    }

    return (
      <div className="flow-root dynamic-comp">
        <ul role="list" className="-mb-8">
          {timeline.map((event, eventIdx) => (
            <li >
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span className="absolute left-3 top-3 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        'h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      <event.icon className="h-4 w-4 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div className={`flex min-w-0 flex-1 justify-between`}>
                    <div>
                      <p className="text-sm text-gray-500">
                        {event.title}{' '}
                        <a 
                          // href={event.href} 
                          onClick={() => handleNotificationClick(event.href, event._id)}
                          className={` ${event.read ? 'text-gray-500' : 'text-gray-900 font-semibold'}  cursor-pointer hover:underline duration-300 overflow-hidden text-ellipsis`}
                        >
                          {event.body}
                        </a>
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime={event.createdAt}>{event.createdAt}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div ref={loader} className='mt-4 text-gray-800' >載入中...</div>
      </div>
    )
  }



export default function NotificationList({
  open, 
  setOpen, 
  setHaveNewNotification, 
}) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const lastItem = useRef(null);
    const [page, setPage] = useState(0);
    const loader = useRef(null);

    // handle observer
    useEffect(() => {
      if (loader.current) {
          var options = {
              root: null,
              rootMargin: "20px",
              threshold: 1.0
          };
      
          const observer = new IntersectionObserver(handleObserver, options);
          observer.observe(loader.current);
      }
  }, [loader.current]);

    // keep track if users have opened the feed before
    // const [openedBefore, setOpenedBefore] = useState(false);
    useEffect(() => {
        if (open) {
            // setOpenedBefore(true);
            // set session storage to true
            sessionStorage.setItem("openedNotificationFeed", true);
            RetrieveNotificationData(page);

            setHaveNewNotification(false);
            console.log("Setting last open notification time")
            // handleReadAllClick();
        }
    }, [open]);

    // fetch notifications from backend
    useEffect(() => {
        RetrieveNotificationData(page);
    }, [page]);

    // handle observer
    const handleObserver = (entities) => {
        // skip if no data is loaded
        if (notifications.length === 0) {
          return;
        }

        // check if the last item is in the viewport
        const target = entities[0];
        if (target.isIntersecting) {   
            setPage((prev) => prev + 1);
        }
    }

    // set automatic refresh every fixed seconds 
    useEffect(() => {
        const interval = setInterval(() => {
          if (!open) {
            // console.log("Refreshing notification feed")
            setPage(0)
            RetrieveNotificationData(0);
          }
        }, 15000);

        return () => clearInterval(interval);
    }, [open]);


    function RetrieveNotificationData(page = 0, limit = 15) {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification?userid=" + localStorage.getItem("userid") + "&page=" + page + "&limit=" + limit + "&organizationID=" + localStorage.getItem("organizationId");
        fetch(url,{
          headers: {
            "Authorization": sessionStorage.getItem("idToken")
          }
        })
            .then(response => response.json())
            .then(rawData => {
                let data = rawData['notifications'];

                // get first date 
                if (data.length > 0) {
                    if (page === 0) {
                        let then = new Date(data[0].createdAt);
                        // minus 8 hours to get the correct time
                        then.setHours(then.getHours() - 8);
                        let lastOpenNotificationTime = sessionStorage.getItem("lastOpenNotificationTime");

                        // compare lastOpenNotificationTime with the first notification time. If lastOpenNotificationTime is before the first notification time, set haveNewNotification to true
                        if (!lastOpenNotificationTime || (lastOpenNotificationTime && lastOpenNotificationTime < then.getTime())) {
                            // go through data if all read is true, set haveNewNotification to false
                            let haveNewNotification = false;
                            data.forEach(notification => {
                                if (!notification.read) {
                                    haveNewNotification = true;
                                }
                            });

                            if (haveNewNotification) {
                                // console.log("New notification found")
                                setHaveNewNotification(true);
                            } else {
                                // console.log("No new notification found")
                                setHaveNewNotification(false);
                            }
                        } else {
                            // console.log("No new notification found")
                            setHaveNewNotification(false);
                        }

                    }
                }

                // adding icons to each notification (New Job: briefcase)
                data.forEach(notification => {
                    if (notification.title === "New Job") {
                        notification.icon = BriefcaseIcon;
                        notification.iconBackground = "bg-indigo-500";
                    } else {  // other use information-circle
                        notification.icon = InformationCircleIcon;
                        notification.iconBackground = "bg-gray-400";
                    }

                    notification.createdAt = timeSince(notification.createdAt);
                    
                });

                // append new data to existing data
                if (page === 0) {
                    setNotifications(data);
                } else {
                  setNotifications(prev => [...prev, ...data]);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }

    // handle read all button click
    const handleReadAllClick = () => {
        // set all notifications to read
        let fetchUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/notification/read-all"
        fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userid: localStorage.getItem("userid")
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log("Retrieving new data ")
            RetrieveNotificationData(0)
            setHaveNewNotification(false);
        })
        .catch(error => {
            console.log(error);
        });
    }


    return (
      // <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen} id="notification-list-slide-over" open={open}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                {/* <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                > */}
                  <DialogPanel transition className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                            通知中心
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* create a mark all as read button */}
                      <div className='flex justify-end px-4 sm:px-6 mt-3'>
                        <button
                          className='text-indigo-600 hover:text-indigo-900 font-semibold text-sm'
                          onClick={handleReadAllClick}
                        >
                          設所有通知為已讀
                        </button>
                      </div>
                      <div className="relative mt-4 flex-1 px-4 sm:px-6"><NotificationFeed timeline={notifications} loader={loader} /></div>
                    </div>
                  </DialogPanel>
                {/* </Transition.Child> */}
              </div>
            </div>
          </div>
        </Dialog>
      // </Transition.Root>
    )
}

// group time of notification using Just now, A few minutes ago, X Hours ago, Yesterday, X Days ago, X Weeks ago, X Months ago, X Years ago
function timeSince(date) {
    const now = new Date();
    const then = new Date(date);
    // minus 8 hours to get the correct time
    then.setHours(then.getHours() - 8);
    const secondsPast = (now.getTime() - then.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return '剛剛';
    }
    if (secondsPast < 3600) {
      const minutes = Math.floor(secondsPast / 60);
      return `${minutes} 分鐘前`;
    }
    if (secondsPast <= 86400) {
      const hours = Math.floor(secondsPast / 3600);
      return `${hours} 小時前`;
    }
    if (secondsPast <= 172800) { // Up to 48 hours
      return '昨天';
    }
    if (secondsPast < 604800) {
      const days = Math.floor(secondsPast / 86400);
      return `${days} 日前`;
    }
    if (secondsPast < 2592000) {
      const weeks = Math.floor(secondsPast / 604800);
      return `${weeks} 星期前`;
    }
    if (secondsPast < 31536000) {
      const months = Math.floor(secondsPast / 2592000);
      return `${months} 個月前`;
    }
    const years = Math.floor(secondsPast / 31536000);
    return `${years} 年前`;
  }


