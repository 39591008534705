import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import icons from heroicons
import { 
    TrashIcon,
    PencilIcon,
    UserCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import GlobalVars from '../../Config';
// components
import NewBoardItems from './Components/NewBoardItems';
import BoardsComp from './Components/BoardsComp';
import FileList from './Components/FileList';
import Note from './Components/Note';
import NewBoard from './Components/NewBoard';
import RemarkDialog from './Components/RemarkDialog';

import { useNavigate } from 'react-router-dom';


export default function ProjectManagementBoard() {
    // document title
    document.title = "項目管理";

    const navigate = useNavigate();

    // get current board id from url (e.g. /project-management/123)
    const { id } = useParams();

    // if id is not 24 characters, redirect to 404 page
    if (id.length !== 24) {
        console.log('id is not 24 characters, redirect to 404 page');
        navigate('/404');
    }

    // get project details
    useEffect(() => {
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log(response.json())
                throw new Error('Failed to fetch project details')
            }
        })
        .then(data => {
            setPageTitle(data.projectTitle)
        })
        .catch(error => console.error('Error fetching project details:', error));
    }, [id])
    
    const [boards, setBoards] = useState([{
        "boardName": "未開始",
    }]);

    
    const [currentTab, setCurrentTab] = useState("項目版");
    const [newBoardItemsOpen, setNewBoardItemsOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState("Lee Kam Kee");
    const [pageDescription, setPageDescription] = useState("A list of all the files in this project.");
    const [newBoardOpen, setNewBoardOpen] = useState(false);
    const [remarkDialogOpen, setRemarkDialogOpen] = useState(false);
    const [activeBoardID, setActiveBoardID] = useState('');

    // get current tab by url query params
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const currentTab = searchParams.get('tab') || "項目版";
        setCurrentTab(currentTab);
    }, [])

    return (
        <ApplicationShell>
            <PageHeader 
                title={pageTitle}
                description={pageDescription}
                id={id}
                setTitle={setPageTitle}
                setNewBoardOpen={setNewBoardOpen}
            />
            <PageTabs 
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                navigate={navigate}
                id={id}
            />
            <div className="mt-4">
                {currentTab === "項目版" && 
                    <BoardList 
                        newBoardItemsOpen={newBoardItemsOpen}
                        setNewBoardItemsOpen={setNewBoardItemsOpen}
                        setRemarkDialogOpen={setRemarkDialogOpen}
                        activeBoardID={activeBoardID}
                        setActiveBoardID={setActiveBoardID}
                    />}
                {currentTab === "檔案" && <FileList />}
                {currentTab === "備註" && <Note />}
            </div>
            
            <NewBoard 
                open={newBoardOpen}
                setOpen={setNewBoardOpen}
            />
            <RemarkDialog 
                open={remarkDialogOpen}
                setOpen={setRemarkDialogOpen}
                activeBoardID={activeBoardID}
            />
        </ApplicationShell>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function PageHeader({ title, description, id, setTitle, setNewBoardOpen}) {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedTitle, setUpdatedTitle] = useState(title);

    useEffect(() => {
        setUpdatedTitle(title);
    }, [title])

    const handleEditProject = () => {
        setIsEditing(true);
    }

    const handleTitleChange = (e) => {
        setUpdatedTitle(e.target.value);
    }

    const handleSaveProject = () => {
        setIsEditing(false);
        // create fetch request to update project title
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                projectTitle: updatedTitle,
                projectID: id
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update project title')
            }
        })
        .then(data => {
            
        })
        .catch(error => console.error('Error updating project title:', error));
    }

    const handleCancelEditProject = () => {
        setIsEditing(false);
        setUpdatedTitle(title);

    }

    return (
      <div>
        <div>
          <nav aria-label="Back" className="sm:hidden">
            <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon aria-hidden="true" className="-ml-1 mr-1 size-5 shrink-0 text-gray-400" />
              Back
            </a>
          </nav>
          <nav aria-label="Breadcrumb" className="hidden sm:flex">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div className="flex">
                  <a href="/project-management" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    項目
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                  <a href={`/project-management/${id}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 skip-trans">
                    {updatedTitle}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight skip-trans">
              {isEditing ? (
                <input 
                    type="text" 
                    value={updatedTitle} 
                    onChange={handleTitleChange} 
                    className="border-1 border-gray-300 rounded-md p-1 text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
                />
              ) : (
                updatedTitle
              )}
              {!isEditing ? (
                <button className="ml-2" onClick={handleEditProject}>
                    <PencilIcon className="size-5" />
                </button>
              ):(
                <>
                    <button className="ml-2" onClick={handleSaveProject}>
                        <CheckIcon className="size-5" />
                    </button>
                    <button className="ml-2" onClick={handleCancelEditProject}>
                        <XMarkIcon className="size-5" />
                    </button>
                </>
              )}
            </h2>
          </div>
          <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Edit
            </button> */}
            <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setNewBoardOpen(true)}
            >
              新增項目版
            </button>
          </div>
        </div>
      </div>
    )
}

function PageTabs({ currentTab, setCurrentTab, navigate, id }) {
    const [tabs, setTabs] = useState([
        { name: '項目版', href: '#', current: true },
        { name: '檔案', href: '#', current: false },
        { name: '備註', href: '#', current: false },
    ]);

    const handleTabChange = (e) => {
        setCurrentTab(e);
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.name === e,
        })));

        // update url query params
        navigate(`/project-management/${id}?tab=${e}`);
    }

    useEffect(() => {
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.name === currentTab,
        })));
    }, [currentTab])

    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabs.find((tab) => tab.current).name}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name} onChange={handleTabChange}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  aria-current={tab.current ? 'page' : undefined}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
                  )}
                  onClick={() => handleTabChange(tab.name)}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
}

function BoardList({ 
    newBoardItemsOpen, 
    setNewBoardItemsOpen,
    setRemarkDialogOpen,
    activeBoardID,
    setActiveBoardID
}) {
    const [boards, setBoards] = useState([]);

    // fetch boards
    useEffect(() => {
        let id = window.location.pathname.split('/')[2];
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch boards')
            }
        })
        .then(data => {
            setBoards(data.map(board => ({
                boardName: board.boardTitle,
                boardId: board._id
            })));
        })
        .catch(error => console.error('Error fetching boards:', error));
    }, [])

    return (
        <>
            <ul role="list" className="space-y-3">
                {boards.map((board) => (
                    <li key={board.boardId} className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                        <BoardsComp 
                            boardName={board.boardName} 
                            boardId={board.boardId} 
                            newBoardItemsOpen={newBoardItemsOpen}
                            setNewBoardItemsOpen={setNewBoardItemsOpen}
                            activeBoardID={activeBoardID}
                            setActiveBoardID={setActiveBoardID}
                            setRemarkDialogOpen={setRemarkDialogOpen}
                        />
                    </li>
                ))}
            </ul>
            <NewBoardItems 
                open={newBoardItemsOpen}
                setOpen={setNewBoardItemsOpen}
                boardID={activeBoardID}
            />
        </>
    )
}
