import { useState, useEffect } from 'react';
import AddNewProject from '../AddNewProject';
import { useNavigate } from 'react-router-dom';
import GlobalVars from '../../../../Config';
export default function ProjectList() {
    const [projects, setProjects] = useState([{
        "projectName": "Lee Kam Kee",
        "date": "2024-01-01",
        "responsiblePerson": "張三",
        "id": "1"
    }]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getProjects = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project?organizationID=${localStorage.getItem('organizationId')}`, {
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            setProjects(data.map(project => ({
                projectName: project.projectTitle,
                responsiblePerson: project.assignedTo,
                date: project.projectStartDate,
                id: project._id
            })));
        } catch (err) {
            console.error('Failed to fetch projects:', err);
            setError('Failed to load projects. Please try again later.');
            setProjects([]); // Reset projects or set to default state
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // get the projects from the database
        const projects = getProjects();
    }, []);

    const [openNewProject, setOpenNewProject] = useState(false);
    const navigate = useNavigate();
    return (
      <div className="">
        <div 
          className="sm:flex sm:items-center"
        >
          <div className="sm:flex-auto">
            <h1 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">項目</h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setOpenNewProject(true)}
            >
              新增項目
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {isLoading && <p>加載中...</p>}
              {error && <p>{error}</p>}
              {projects.length === 0 && <p>沒有項目</p>}
              {projects.length > 0 && (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      項目名稱
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      日期
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      負責人
                    </th>
                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th> */}
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">修改</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {projects.map((project) => (
                    <tr key={project.projectName} className="cursor-pointer hover:bg-gray-100 duration-300" onClick={() => navigate(`/project-management/${project.id}`)}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {project.projectName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.date}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.responsiblePerson}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 gap-2">
                        <button
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900 mr-2"
                        >
                            刪除<span className="sr-only">, {project.projectName}</span>
                        </button>
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          修改<span className="sr-only">, {project.projectName}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <AddNewProject open={openNewProject} setOpen={setOpenNewProject} />
      </div>
    )
  }
  